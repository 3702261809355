<template>
  <v-row
    class="mb-8 ml-0 row-style"
    justify="space-between"
    id="calendar_filter"
  >
    <v-col style="max-width: 23%;">
      <v-select
        label="Choose Filter Type"
        outlined
        :color="colorTheme.dropDown"
        :item-color="colorTheme.dropDownItem"
        :items="defaultHeader"
        item-text="text"
        item-value="value"
        v-model="defaultSelect"
      ></v-select>
    </v-col>
    <v-col class="calendar-picker" style="max-width: 47%;">
      <v-col class="margin-fix pt-2 calendar-picker_child">
        <v-menu
          v-model="showFeeFromCalendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedFeeFrom"
              :placeholder="getFeeFromText"
              prepend-icon="mdi-calendar"
              :color="colorTheme.dropDown"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="feeFromSelected"
            :color="colorTheme.datePicker"
            @input="showFeeFromCalendar = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col style="max-width: 1%;" class="mt-2">
        <p>to</p>
      </v-col>
      <v-col class="margin-fix pt-2" style="position: relative;">
        <v-menu
          v-model="showFeeToCalendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedFeeTo"
              :placeholder="getFeeToText"
              prepend-icon="mdi-calendar"
              :color="colorTheme.dropDown"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="feeToSelected"
            :color="colorTheme.datePicker"
            @input="showFeeToCalendar = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-col>
    <v-col style="max-width: 30%">
      <v-row>
        <v-col cols="5" md="5">
          <v-btn
            :color="colorTheme.button"
            :dark="!buttonsDisabled"
            outlined
            @click="close"
            :disabled="buttonsDisabled"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="5" md="5" v-if="!multiPeriodActive">
          <v-btn
            @click="addFilter"
            :color="colorTheme.button"
            :dark="!buttonsDisabled"
            :disabled="buttonsDisabled"
          >
            Add Filter
          </v-btn>
        </v-col>
        <v-col cols="5" md="5" v-else>
          <v-btn
            @click="addFilter"
            :color="colorTheme.button"
            :dark="!buttonsDisabled && !valueNotChanged"
            :disabled="buttonsDisabled || valueNotChanged"
          >
            Update
          </v-btn>
        </v-col>
        <v-col class="icon-card-style" cols="2" md="2">
          <v-icon @click="close" class="exit-icon">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import {
  getMonthYearFromYearMonthPeriod,
  getYearMonthPeriod
} from "@/utils/format-util.js";

export default {
  props: [
    "multiPeriodActive",
    "feeFrom",
    "feeTo",
    "periodRangeFilter",
    "refresh"
  ],
  emits: [
    "update:feeFrom",
    "update:feeTo",
    "update:periodRangeFilter",
    "setFilterCard",
    "clearCalendarMark"
  ],
  data: () => ({
    showFeeFromCalendar: false,
    showFeeToCalendar: false,
    defaultFeeFromText: "Fee from",
    defaultFeeToText: "Fee to",
    defaultHeader: [
      {
        text: "Fee from - Fee to",
        value: "FeeFromFeeTo"
      }
    ],
    defaultSelect: {
      text: "Fee from - Fee to",
      value: "FeeFromFeeTo"
    },
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    periodRange: {
      get() {
        return this.periodRangeFilter;
      },
      set(newValue) {
        this.$emit("update:periodRangeFilter", newValue);
      }
    },
    feeFromSelected: {
      get() {
        return this.feeFrom;
      },
      set(newValue) {
        this.$emit("update:feeFrom", newValue);
      }
    },
    formattedFeeFrom() {
      return getMonthYearFromYearMonthPeriod(this.feeFromSelected);
    },
    feeToSelected: {
      get() {
        return this.feeTo;
      },
      set(newValue) {
        this.$emit("update:feeTo", newValue);
      }
    },
    formattedFeeTo() {
      return getMonthYearFromYearMonthPeriod(this.feeToSelected);
    },
    getFeeFromText() {
      if (this.feeFromSelected) {
        return this.feeFromSelected;
      } else {
        return this.defaultFeeFromText;
      }
    },
    getFeeToText() {
      if (this.feeToSelected) {
        return this.feeToSelected;
      } else {
        return this.defaultFeeToText;
      }
    },
    buttonsDisabled() {
      if (
        this.feeFromSelected === null ||
        this.feeToSelected === null ||
        this.feeFromSelected > this.feeToSelected
      ) {
        return true;
      } else {
        return false;
      }
    },
    valueNotChanged() {
      if (
        this.feeFromSelected === getYearMonthPeriod(this.periodRange.feeFrom) &&
        this.feeToSelected === getYearMonthPeriod(this.periodRange.feeTo)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    addFilter() {
      this.periodRange = {
        feeFrom: Number(this.feeFromSelected.replace("-", "")),
        feeTo: Number(this.feeToSelected.replace("-", "")),
      };
      this.closeCalendarPicker();
      this.$emit("setFilterCard", false);
      this.refresh();
    },
    close() {
      this.closeCalendarPicker();
      this.$emit("clearCalendarMark");
      this.$emit("setFilterCard", false);
    },
    //Closes calendar picker if user did not close before
    closeCalendarPicker() {
      this.showFeeFromCalendar = false;
      this.showFeeToCalendar = false;
    },
  }
};
</script>

<style>
#calendar_filter .calendar-picker > div > div > .v-input__prepend-outer > div {
  position: absolute;
  margin-left: 9rem;
  margin-top: -0.75rem;
}
#calendar_filter > div > div > div > .v-input__slot {
  margin-top: 8px;
  min-height: 48px !important;
  max-height: 48px !important;
}
#calendar_filter .calendar-picker > div > div > div > div > .v-text-field__slot > input {
  padding-bottom: 1.56rem;
  padding-left: 1rem;
}
#calendar_filter div > div > div > div > .v-select__slot .v-input__append-inner {
  margin-top: 12px !important;
}
#calendar_filter > div > .row .icon-card-style > button {
  bottom: 25px;
  left: 10px
}
#calendar_filter > div > .row .icon-card-style > button::after {
  height: 0 !important;
}
.row-style {
  max-height: 90px;
  max-width: 950px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.42);
}
.calendar-picker {
  position: relative;
  display: flex;
  max-height: 90px !important;
}
.margin-fix {
  margin: auto;
  margin-left: 0;
  margin-right: 0;
}
.calendar-picker_child {
  max-width: 50%;
  max-height: 90px;
}
.icon-card-style {
  display: flex;
  justify-content: center;
}
</style>

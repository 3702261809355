<template>
  <div class="ml-0 pl-0" style="display: flex">
    <div class="mb-3" v-if="!filterCardVisible">
      <span v-if="multiPeriodActive" @click="showFilterCard">
        <v-chip
          close
          @click:close="removeActivePeriodChip"
          style="cursor: pointer"
        >
          <v-icon class="pr-1" style="font-size: 22px;">
            mdi-calendar
          </v-icon>
          {{ chipDate(periodRangeFilter) }}
        </v-chip>
      </span>
      <add-filter-button
        v-if="!multiPeriodActive"
        :showFilter="showFilterCard"
        :theme="theme"
      ></add-filter-button>
    </div>
    <fee-from-fee-to-filter-card
      v-else
      :multiPeriodActive="multiPeriodActive"
      :feeFrom.sync="feeFromSelected"
      :feeTo.sync="feeToSelected"
      :periodRangeFilter.sync="periodRange"
      :refresh="refresh"
      @setFilterCard="setFilterCard"
      @clearCalendarMark="clearCalendarMark"
    ></fee-from-fee-to-filter-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { formatPeriod, getYearMonthPeriod } from "@/utils/format-util.js";
import AddFilterButton from "@/components/filters/AddFilterButton.vue";
import FeeFromFeeToFilterCard from "./FeeFromFeeToFilterCard.vue";

export default {
  components: {
    AddFilterButton,
    FeeFromFeeToFilterCard
  },
  props: ["multiPeriodActive", "refresh", "theme"],
  data: () => ({
    feeFromSelected: null,
    feeToSelected: null,
    filterCardVisible: false,
  }),
  computed: {
    ...mapState("feeResultsRecords", ["periodRangeFilter"]),
    periodRange: {
      get() {
        return this.periodRangeFilter;
      },
      set(newValue) {
        this.setPeriodRangeFilter(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("feeResultsRecords", [
      "setPeriodRangeFilter",
      "resetPeriodRangeFilter"
    ]),
    //Check value for input to show
    setDefaultValue() {
      if (!this.multiPeriodActive) {
        this.feeFromSelected = null;
        this.feeToSelected = null;
      } else {
        this.feeFromSelected = getYearMonthPeriod(this.periodRange.feeFrom);
        this.feeToSelected = getYearMonthPeriod(this.periodRange.feeTo);
      }
    },
    setFilterCard(value) {
      this.setDefaultValue();
      this.filterCardVisible = value;
    },
    showFilterCard() {
      this.setFilterCard(true);
    },
    removeActivePeriodChip() {
      this.resetPeriodRangeFilter();
      this.setFilterCard(false);
      this.clearCalendarMark();
      this.refresh();
    },
    chipDate(periodRange) {
      let from = formatPeriod(periodRange.feeFrom);
      let to = formatPeriod(periodRange.feeTo);
      return `Fee from ${from} to ${to}`;
    },
    //Clears calendar from previous tag
    clearCalendarMark() {
      this.feeFromSelected = null;
      this.feeToSelected = null;
    }
  }
};
</script>

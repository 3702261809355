<template>
  <v-btn
    tile
    class="mb-2 ml-2 btn-letter_transform"
    :color="theme.button"
    :dark="!disabled"
    @click="action"
    :loading="loadingBar"
    :disabled="disabled"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
export default {
  props: ["buttonText", "theme", "disabled", "loading", "action"],
  data: () => ({}),
  computed: {
    loadingBar() {
      if (this.loading) {
        return this.loading;
      } else {
        return false;
      }
    }
  },
  methods: {}
};
</script>

<template>
  <div>
    <v-dialog v-model="show" max-width="400px" @keydown.esc="close">
      <running-dialog
        message="Updating note. Please wait..."
        :showDialog.sync="updatingBillingStatusNote"
      ></running-dialog>
      <success-dialog
        message="Updated successfully"
        :showDialog.sync="updateSuccessDialog"
      ></success-dialog>
      <error-dialog
        message="Update error!"
        :showDialog.sync="updateErrorDialog"
      ></error-dialog>
      <v-card elevation="24">
        <v-card-title>
          <span class="headline">
            {{ headerText }}
          </span>
          <span class="right-corner">
            <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
          </span>
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-textarea
            class="px-4 mt-4"
            label="Note"
            placeholder=" "
            v-model="newNote"
            dense
            outlined
            rows="1"
            auto-grow
            no-resize
            :color="colorTheme.textField"
            :rules="noteRules"
            hide-details="auto"
          ></v-textarea>
        </v-form>
        <v-card-actions v-if="emptyNote || changedValue" class="pr-4">
          <v-spacer></v-spacer>
          <v-btn
            class="btn-letter_transform"
            :style="colorTheme.textColor"
            :color="colorTheme.buttonWhite"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            class="btn-letter_transform"
            @click="save"
            :color="colorTheme.button"
            :dark="!disabled"
            :disabled="disabled"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-row class="ml-0 mr-0">
          <span>
            <v-card-subtitle class="pt-3 pb-0"> Date </v-card-subtitle>
            <v-card-text> {{ date }} </v-card-text>
          </span>
          <span>
            <v-card-subtitle class="pt-3 pb-0"> Author </v-card-subtitle>
            <v-card-text> {{ author }} </v-card-text>
          </span>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDateTextMonth } from "@/utils/format-util.js";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";

export default {
  components: {
    RunningDialog,
    SuccessDialog,
    ErrorDialog,
  },
  props: ["showDialog", "selectedNote"],
  emits: ["update:showDialog", "update:selectedNote", "refresh"],
  data: () => ({
    valid: false,
    updatingBillingStatusNote: false,
    updateSuccessDialog: false,
    updateErrorDialog: false,
    oldNote: null,
    newNote: null,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("accountStatus", ["noteMaxCharacters"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    noteRules() {
      return [
        v => !!(v || "").trim() || "Note must not be empty",
        v =>
          (v || "").trim().length <= this.noteMaxCharacters ||
          `A maximum of ${this.noteMaxCharacters} characters is allowed`
      ];
    },
    disabled() {
      return !this.valid || !this.changedValue;
    },
    emptyNote() {
      return !this.newNote || this.newNote.trim().length === 0;
    },
    changedValue() {
      if (!this.newNote || this.newNote.trim().length === 0) {
        return false;
      }
      return this.newNote.trim() !== this.oldNote;
    },
    billingStatusNote: {
      get() {
        return this.selectedNote;
      },
      set(newValue) {
        this.$emit("update:selectedNote", newValue);
      }
    },
    headerText() {
      if (this.billingStatusNote) {
        return this.billingStatusNote.noteOperation;
      }
      return "";
    },
    date() {
      if (this.billingStatusNote) {
        return formatDateTextMonth(this.billingStatusNote.date);
      }
      return "";
    },
    author() {
      if (this.billingStatusNote) {
        return this.billingStatusNote.author;
      }
      return "";
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.oldNote = this.billingStatusNote.note.trim();
        this.newNote = this.billingStatusNote.note.trim();
      }
    },
  },
  methods: {
    ...mapActions("accountStatus", ["updateBillingStatusNote"]),
    async save() {
      let payload = {
        note: this.billingStatusNote,
        newNoteText: this.newNote
      };
      this.updatingBillingStatusNote = true;
      let result = await this.updateBillingStatusNote(payload);
      this.updatingBillingStatusNote = false;
      this.close();
      if (result) {
        this.updateSuccessDialog = true;
        this.$emit("refresh");
      } else {
        this.updateErrorDialog = true;
      }
    },
    cancel() {
      this.newNote = this.oldNote;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    close() {
      this.resetForm();
      this.billingStatusNote = null;
      this.show = false;
    },
  }
};
</script>

<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-icon :color="colorTheme.warning"> mdi-alert </v-icon>
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
  },
};
</script>

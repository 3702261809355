<template>
  <v-dialog v-model="show" width="unset" @keydown.esc="close">
    <running-dialog
      message="Getting AUM Information. Please wait..."
      :showDialog.sync="loading"
    ></running-dialog>
    <error-dialog
      :message="aumErrorMessage"
      :showDialog.sync="showAumError"
    ></error-dialog>
    <v-card>
      <v-container fluid>
        <v-card-title class="mr-2">
          <v-data-table
            fixed-header
            :headers="accountHeaders"
            :items="accountItems"
            item-key="name"
            hide-default-footer
          >
            <template v-slot:[`item.billingPeriod`]="{ item }">
              {{ getPeriodString(item.billingPeriod) }}
            </template>
            <template v-slot:[`item.hasOtherExclusion`]="{ item }">
              {{ item.hasOtherExclusion ? "Yes" : "No" }}
            </template>
            <template v-slot:[`item.hasCashExclusion`]="{ item }">
              {{ item.hasCashExclusion ? "Yes" : "No" }}
            </template>
          </v-data-table>
        </v-card-title>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
        <v-row justify="center">
          <v-icon
            color="black"
            :disabled="noPreviousPeriod"
            @click="getFirstPeriodAumData"
          >
            mdi-chevron-double-left
          </v-icon>
          <v-icon
            color="black"
            :disabled="noPreviousPeriod"
            @click="getPreviousPeriodAumData"
          >
            mdi-chevron-left
          </v-icon>
          <v-icon
            color="black"
            :disabled="noNextPeriod"
            @click="getNextPeriodAumData"
          >
            mdi-chevron-right
          </v-icon>
          <v-icon
            color="black"
            :disabled="noNextPeriod"
            @click="getLastPeriodAumData"
          >
            mdi-chevron-double-right
          </v-icon>
        </v-row>
      </v-container>
      <v-divider style="border-width: 0.5px; border-color: black;"></v-divider>
      <v-container fluid>
        <v-row justify="space-between" class="ml-0 mr-0">
          <span class="pl-6 pt-3">
            <cell-clipboard
              :text="aumItemsText"
              :hoverText="'Copy table to clipboard'"
              :large="true"
            ></cell-clipboard>
          </span>
          <span style="font-size: 14px; text-align: right" class="pr-6">
            <v-card-text class="pl-0 pr-0 pt-0 pb-0 text-right">
              Average Daily Balance:
            </v-card-text>
            <span v-if="accountAumData && accountAumData.averageValue">
              <cell-monetary-value-no-whitespace :value="accountAumData.averageValue" />
            </span>
          </span>
        </v-row>
        <v-container>
          <v-data-table
            fixed-header
            dense
            :headers="aumHeaders"
            :items="aumItems"
            item-key="allocationValueDate"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:[`item.allocationValueDate`]="{ item }">
              <date-cell :value="item.allocationValueDate" />
            </template>
            <template v-slot:[`item.aumValue`]="{ item }">
              <cell-monetary-value-no-whitespace :value="item.aumValue" />
            </template>
            <template v-slot:[`item.cashValue`]="{ item }">
              <cell-monetary-value-no-whitespace :value="item.cashValue" />
            </template>
            <template v-slot:[`item.nonCashExclusionValue`]="{ item }">
              <cell-monetary-value-no-whitespace :value="item.nonCashExclusionValue" />
            </template>
            <template v-slot:[`item.netValue`]="{ item }">
              <cell-monetary-value-no-whitespace :value="item.netValue" />
            </template>
          </v-data-table>
          <v-container v-if="showPriorAdvance" class="pt-0 pl-0 grey-text">
            <v-card-text class="pb-0"> Prior Advance </v-card-text>
            <v-data-table
              class="grey-text"
              dense
              :headers="aumHeaders"
              :items="priorAdvanceItems"
              item-key="allocationValueDate"
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:[`item.allocationValueDate`]="{ item }">
                <date-cell :value="item.allocationValueDate" />
              </template>
              <template v-slot:[`item.aumValue`]="{ item }">
                <cell-monetary-value-no-whitespace :value="item.aumValue" />
              </template>
              <template v-slot:[`item.cashValue`]="{ item }">
                <cell-monetary-value-no-whitespace :value="item.cashValue" />
              </template>
              <template v-slot:[`item.nonCashExclusionValue`]="{ item }">
                <cell-monetary-value-no-whitespace :value="item.nonCashExclusionValue" />
              </template>
              <template v-slot:[`item.netValue`]="{ item }">
                <cell-monetary-value-no-whitespace :value="item.netValue" />
              </template>
            </v-data-table>
          </v-container>
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from "@/utils/format-util.js";
import { getPreviousRunInt, getNextRunInt } from "@/utils/period-util.js";
import { mapActions, mapState } from "vuex";
import CellMonetaryValueNoWhitespace from "@/components/common/CellMonetaryValueNoWhitespace.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import DateCell from "@/components/common/DateCell.vue";

export default {
  components: {
    CellMonetaryValueNoWhitespace,
    ErrorDialog,
    RunningDialog,
    CellClipboard,
    DateCell,
  },
  props: ["showDialog", "aumData"],
  emits: ["update:showDialog", "update:aumData"],
  data: () => ({
    loading: false,
    aumErrorMessage: "",
    showAumError: false,
    accountHeaders: [
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        align: "center",
      },
      {
        text: "Billing Frequency",
        value: "billingType",
        sortable: false,
        align: "center",
      },
      {
        text: "Billing Period",
        value: "billingPeriod",
        sortable: false,
        align: "center",
      },
      {
        text: "Local Exclusions?",
        value: "hasOtherExclusion",
        sortable: false,
        align: "center",
      },
      {
        text: "Exclude Cash?",
        value: "hasCashExclusion",
        sortable: false,
        align: "center",
      },
    ],
    aumHeaders: [
      {
        text: "Date",
        value: "allocationValueDate",
        sortable: false,
        align: "center",
      },
      {
        text: "AUM",
        value: "aumValue",
        sortable: false,
        align: "end",
      },
      {
        text: "Cash",
        value: "cashValue",
        sortable: false,
        align: "end",
      },
      {
        text: "Non-cash exclusions",
        value: "nonCashExclusionValue",
        sortable: false,
        align: "end",
      },
      {
        text: "Net",
        value: "netValue",
        sortable: false,
        align: "end",
      },
    ],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    accountAumData: {
      get() {
        return this.aumData;
      },
      set(newValue) {
        this.$emit("update:aumData", newValue);
      }
    },
    currentPeriod() {
      if (this.accountAumData && this.accountAumData.billingPeriod) {
        return this.accountAumData.billingPeriod;
      } else {
        return 0;
      }
    },
    firstPeriod() {
      if (this.accountAumData && this.accountAumData.firstPeriod) {
        return this.accountAumData.firstPeriod;
      } else {
        return 0;
      }
    },
    lastPeriod() {
      if (this.accountAumData && this.accountAumData.lastPeriod) {
        return this.accountAumData.lastPeriod;
      } else {
        return 0;
      }
    },
    previousPeriod() {
      return getPreviousRunInt(this.currentPeriod);
    },
    nextPeriod() {
      return getNextRunInt(this.currentPeriod);
    },
    noPreviousPeriod() {
      return this.currentPeriod <= this.firstPeriod;
    },
    noNextPeriod() {
      return this.currentPeriod >= this.lastPeriod;
    },
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    accountItems() {
      if (this.accountAumData) {
        return [this.accountAumData];
      } else {
        return [];
      }
    },
    aumItems() {
      if (this.accountAumData && this.accountAumData.hisImpDataList) {
        return this.accountAumData.hisImpDataList;
      } else {
        return [];
      }
    },
    priorAdvanceItems() {
      if (this.showPriorAdvance) {
        return this.accountAumData.priorAdvance;
      } else {
        return [];
      }
    },
    showPriorAdvance() {
      return (
        this.accountAumData &&
        this.accountAumData.priorAdvance &&
        this.accountAumData.priorAdvance.length > 0
      );
    },
    aumItemsText() {
      let aumData = this.aumItems;
      let clipboardTextWithColumns = this.addColumnNamesToClipboardText();
      let clipboardText = this.addRowsToClipboardText(
        clipboardTextWithColumns,
        aumData
      );
      if (clipboardText !== null && clipboardText !== undefined) {
        return clipboardText;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("billingRecordsCalculations", ["getAccountAumData"]),
    async getFirstPeriodAumData() {
      await this.getDataForPeriod(this.firstPeriod);
    },
    async getLastPeriodAumData() {
      await this.getDataForPeriod(this.lastPeriod);
    },
    async getPreviousPeriodAumData() {
      await this.getDataForPeriod(this.previousPeriod);
    },
    async getNextPeriodAumData() {
      await this.getDataForPeriod(this.nextPeriod);
    },
    async getDataForPeriod(period) {
      let finished = false;
      setTimeout(() => {
        if (!finished) {
          this.loading = true;
        }
      }, 1000);
      let result = await this.getAccountAumData({
        accountId: this.accountAumData.accountId,
        billingPeriod: period
      });
      await this.refresh(result);
      finished = true;
      this.loading = false;
    },
    async refresh(aumData) {
      if (aumData.notFound) {
        this.aumErrorMessage = aumData.message;
        this.showAumError = true;
        this.close();
        return;
      }
      aumData.accountName = this.accountAumData.accountName;
      aumData.billingType = this.accountAumData.billingType;
      aumData.firstPeriod = this.accountAumData.firstPeriod;
      aumData.lastPeriod = this.accountAumData.lastPeriod;
      this.accountAumData = aumData;
    },
    close() {
      this.accountAumData = null;
      this.show = false;
    },
    getPeriodString(periodInt) {
      if (periodInt) {
        let periodString = periodInt.toString();
        let year = periodString.slice(0, 4);
        let month = periodString.slice(4, 6);
        return year + "-" + month;
      }
    },
    addColumnNamesToClipboardText() {
      let text = "";
      this.aumHeaders.forEach(header => {
        text += header.text + ",";
      });
      if (text.length > 0 && text[text.length - 1] === ",") {
        text = text.slice(0, -1);
      }
      return text;
    },
    addRowsToClipboardText(text, data) {
      let fullText = text;
      data.forEach(a => {
        fullText += "\n";
        if (a.allocationValueDate) {
          fullText += formatDate(a.allocationValueDate);
        }
        fullText += ",";
        fullText += "$ " + a.aumValue + ",";
        fullText += "$ " + a.cashValue + ",";
        fullText += "$ " + a.nonCashExclusionValue + ",";
        fullText += "$ " + a.netValue;
      });
      return fullText;
    },
  }
};
</script>

<style scoped>
.grey-text {
  color: grey !important;
  font-style: italic;
}
.grey-text > .v-data-table >>> th {
  color: grey !important;
  font-weight: 400;
  font-style: italic;
}
</style>

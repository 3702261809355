var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{staticClass:"scrollable-table__medium",attrs:{"fixed-header":"","headers":_vm.feeHeaders,"items":_vm.account.fees,"item-key":"name","single-expand":"","show-expand":"","expanded":_vm.expandedItems,"hide-default-footer":_vm.rowNumberCheck(_vm.account.fees),"footer-props":{ 'items-per-page-options': _vm.rowsPerPage }},on:{"update:expanded":function($event){_vm.expandedItems=$event},"item-expanded":_vm.expandFees},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.mapFeeType(item.name))+" ")])]}},{key:"item.effectiveRate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.getFeeTypeEffectiveRate(item))+" ")])]}},{key:"item.aumBasis",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value',{attrs:{"value":_vm.getFeeTypeAumBasis(item)}})]}},{key:"item.aum",fn:function(ref){return [_c('cell-monetary-value',{attrs:{"value":_vm.getFeeTypeAum(_vm.account)}})]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-format',{attrs:{"value":_vm.getFeeTypeFee(item)}})]}},{key:"item.topTierRate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.getFeeTypeTopTierRate(item))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('sleeve-table',{attrs:{"headers":headers,"feeType":item,"expandedSleeves":_vm.sleeves,"refreshAccount":_vm.refreshAccount},on:{"update:expandedSleeves":function($event){_vm.sleeves=$event},"update:expanded-sleeves":function($event){_vm.sleeves=$event}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <error-dialog
      :message.sync="expandErrorMessage"
      :showDialog.sync="expandErrorDialog"
    ></error-dialog>
    <v-data-table
      fixed-header
      :class="{ 'scrollable-table__large': true, 'all-selected': allSelected }"
      :headers="filteredAccountHeaders"
      :items="items"
      v-model="selectedAccounts"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="itemsCount"
      item-key="runTypeKey"
      single-expand
      show-expand
      show-select
      :expanded.sync="expandedItems"
      @item-expanded="expandAccounts"
      :footer-props="{
        'items-per-page-options': rowsPerPage,
        disablePagination: loading,
        disableItemsPerPage: loading,
      }"
    >
      <template v-if="allSelected" v-slot:[`header.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled />
      </template>
      <template v-if="allSelected" v-slot:[`item.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled />
      </template>
      <template v-slot:progress>
        <v-progress-linear
          :color="colorTheme.progress"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-slot:[`item.accountId`]="{ item }">
        <cell-clipboard :text="item.accountId" />
      </template>
      <template v-slot:[`item.totalAumAndDate.aum`]="{ item }">
        <cell-monetary-value-with-warning
          v-if="item.totalAumAndDate"
          :value="item.totalAumAndDate.aum"
          :show-error="true"
          error-message="No AUM"
        />
        <cell-warning v-else text="No AUM" />
      </template>
      <template v-slot:[`item.totalAumAndDate.aumDate`]="{ item }">
        <td class="no-wrap">
          <span v-if="item.totalAumAndDate && item.totalAumAndDate.aumDate">
            {{ formatDate(item.totalAumAndDate.aumDate) }}
          </span>
          <cell-warning v-else text="No AUM Date" />
        </td>
      </template>
      <template v-slot:[`item.displayFromDate`]="{ item }">
        <date-cell :value="item.displayFromDate" />
      </template>
      <template v-slot:[`item.displayToDate`]="{ item }">
        <date-cell :value="item.displayToDate" />
      </template>
      <template v-slot:[`item.fee`]="{ item }">
        <cell-monetary-value-format :value="item.amount" />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <fee-types-table
          :headers="headers"
          :account="item"
          :expandedFees.sync="fees"
          :expandedSleeves.sync="sleeves"
          :refreshAccount="refreshAccount"
        ></fee-types-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValueFormat from "@/components/common/CellMonetaryValueFormat.vue";
import CellMonetaryValueWithWarning from "@/components/common/CellMonetaryValueWithWarning.vue";
import CellWarning from "@/components/common/CellWarning.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import FeeTypesTable from "./FeeTypesTable.vue";
import DateCell from "@/components/common/DateCell.vue";

export default {
  components: {
    CellClipboard,
    CellMonetaryValueFormat,
    CellMonetaryValueWithWarning,
    CellWarning,
    ErrorDialog,
    FeeTypesTable,
    DateCell,
  },
  props: [
    "items",
    "selected",
    "allSelected",
    "loading",
    "options",
    "itemsCount",
    "expandedAccounts",
    "expandedFees",
    "expandedSleeves",
    "resetExpandedAccounts",
    "resetExpandedFees",
    "resetExpandedSleeves",
    "refreshAccount",
    "formatDate",
  ],
  emits: [
    "update:selected",
    "update:options",
    "update:expandedAccounts",
    "update:expandedFees",
    "update:expandedSleeves",
  ],
  data: () => ({
    expandErrorDialog: false,
    expandErrorMessage: "",
    defaultErrorMessage: "Account doesn't have billing records in this period",
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage", "minRowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["accountHeaders"]),
    filteredAccountHeaders() {
      return this.accountHeaders.filter(h => !h.dontShow);
    },
    selectedAccounts: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit("update:selected", newValue);
      }
    },
    pagination: {
      get() {
        return this.options;
      },
      set(newValue) {
        this.$emit("update:options", newValue);
      }
    },
    expandedItems: {
      get() {
        return this.expandedAccounts;
      },
      set(newValue) {
        this.$emit("update:expandedAccounts", newValue);
      }
    },
    expandedAccount() {
      return this.expandedItems[0];
    },
    fees: {
      get() {
        return this.expandedFees;
      },
      set(newValue) {
        this.$emit("update:expandedFees", newValue);
      }
    },
    sleeves: {
      get() {
        return this.expandedSleeves;
      },
      set(newValue) {
        this.$emit("update:expandedSleeves", newValue);
      }
    },
  },
  methods: {
    expandAccounts({ item }) {
      let account = item;
      if (this.emptyExpandedAccounts()) {
        if (this.emptyFees(account)) {
          this.showExpandErrorDialog(account);
          this.resetExpandedAccounts();
        }
        return;
      }
      if (this.accountIsExpanded(account)) {
        this.resetExpandedFees();
        this.resetExpandedSleeves();
        return;
      }
      // Other account is expanded
      if (this.emptyFees(account)) {
        this.showExpandErrorDialog(account);
        this.revertExpandedAccounts(this.expandedAccount);
      } else {
        this.resetExpandedFees();
        this.resetExpandedSleeves();
      }
      return;
    },
    emptyExpandedAccounts() {
      return this.expandedItems.length == 0;
    },
    emptyFees(account) {
      return account.fees.length == 0;
    },
    accountIsExpanded(account) {
      return this.expandedAccount == account;
    },
    revertExpandedAccounts(expanded) {
      this.resetExpandedAccounts();
      this.expandedItems = [expanded];
    },
    showExpandErrorDialog(account) {
      if (account.displayErrorMessage) {
        this.expandErrorMessage = account.displayErrorMessage;
      } else {
        this.expandErrorMessage = this.defaultErrorMessage;
      }
      this.expandErrorDialog = true;
    },
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('running-dialog',{attrs:{"message":"Getting AUM Information. Please wait...","showDialog":_vm.gettingAumInfo},on:{"update:showDialog":function($event){_vm.gettingAumInfo=$event},"update:show-dialog":function($event){_vm.gettingAumInfo=$event}}}),_c('info-dialog',{attrs:{"message":_vm.noAumMessage,"showDialog":_vm.showNoAumMessage},on:{"update:showDialog":function($event){_vm.showNoAumMessage=$event},"update:show-dialog":function($event){_vm.showNoAumMessage=$event}}}),_c('error-dialog',{attrs:{"message":"AUM error!","showDialog":_vm.aumError},on:{"update:showDialog":function($event){_vm.aumError=$event},"update:show-dialog":function($event){_vm.aumError=$event}}}),_c('billing-status-note-details',{attrs:{"showDialog":_vm.showNoteDetails,"selectedNote":_vm.selectedNote},on:{"update:showDialog":function($event){_vm.showNoteDetails=$event},"update:show-dialog":function($event){_vm.showNoteDetails=$event},"refresh":_vm.refresh}}),_c('account-aum-details',{attrs:{"showDialog":_vm.showAumDetails,"aumData":_vm.accountAumData},on:{"update:showDialog":function($event){_vm.showAumDetails=$event},"update:show-dialog":function($event){_vm.showAumDetails=$event},"update:aumData":function($event){_vm.accountAumData=$event},"update:aum-data":function($event){_vm.accountAumData=$event}}}),_c('missing-accounts-info',{attrs:{"screen":_vm.screen,"totalResults":_vm.totalAccounts,"totalDistinctResults":_vm.totalDistinctAccounts,"filters":_vm.filters,"loading":_vm.loading}}),_c('v-data-table',{class:{
      'all-selected': _vm.allSelected,
      'scrollable-table__medium': true,
      'table-header_align': true
    },attrs:{"fixed-header":"","headers":_vm.headersSelected,"items":_vm.accounts,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAccounts,"show-select":"","item-key":"tableId","single-select":false,"footer-props":{
      'items-per-page-options': _vm.rowsPerPage,
      disablePagination: _vm.loading,
      disableItemsPerPage: _vm.loading
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.allSelected)?{key:"header.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":true,"disabled":""}})]}}:null,(_vm.allSelected)?{key:"item.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":true,"disabled":""}})]}}:null,{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.theme.progress,"indeterminate":""}})]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.id}})]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(item.status)+" "),(_vm.screen === 'feeReview' && item.billingStatusNote)?_c('v-img',{staticClass:"ml-2",staticStyle:{"max-inline-size":"100%","block-size":"auto","cursor":"pointer"},attrs:{"src":require("@/assets/notes.png"),"width":"24","contain":""},on:{"click":function($event){return _vm.showNoteDialog(item.billingStatusNote)}}}):_vm._e()],1)]}},{key:"item.aum",fn:function(ref){
    var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showAumData(item)}}},[_c('cell-monetary-value',{staticClass:"link-text",attrs:{"value":item.aum}})],1)]}},{key:"item.aumDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.aumDate}})]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('cell-monetary-value',{attrs:{"value":item.amount}})],1)]}},{key:"item.priorUnpaid",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('cell-monetary-value',{attrs:{"value":item.priorUnpaid}})],1)]}},{key:"item.billingStartDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.billingStartDate}})]}},{key:"item.billingEndDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.billingEndDate}})]}},{key:"item.billingRunType",fn:function(ref){
    var item = ref.item;
return [(item.billingRunType)?_c('span',[_vm._v(" "+_vm._s(_vm.formatBillingRunType(item.billingRunType))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="show" persistent :width="widthSet" @keydown.esc="no">
    <v-card>
      <v-card-title> {{ message }} </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="colorTheme.textColor"
          :color="colorTheme.buttonWhite"
          @click="no"
        >
          Cancel
        </v-btn>
        <v-btn dark :color="colorTheme.button" @click="yes">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showDialog", "message", "width"],
  emits: ["update:showDialog", "confirm", "cancel"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme", "textColor"]),
    widthSet() {
      if (this.width === undefined) {
        return "unset";
      } else {
        return this.width;
      }
    },
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  },
  methods: {
    no() {
      this.$emit("cancel");
      this.$emit("update:showDialog", false);
    },
    yes() {
      this.$emit("confirm");
      this.$emit("update:showDialog", false);
    }
  }
};
</script>

<template>
  <div>
    <running-dialog
      message="Getting AUM Information. Please wait..."
      :showDialog.sync="gettingAumInfo"
    ></running-dialog>
    <info-dialog
      :message="noAumMessage"
      :showDialog.sync="showNoAumMessage"
    ></info-dialog>
    <error-dialog
      message="AUM error!"
      :showDialog.sync="aumError"
    ></error-dialog>
    <billing-status-note-details
      :showDialog.sync="showNoteDetails"
      :selectedNote="selectedNote"
      @refresh="refresh"
    ></billing-status-note-details>
    <account-aum-details
      :showDialog.sync="showAumDetails"
      :aumData.sync="accountAumData"
    ></account-aum-details>
    <missing-accounts-info
      :screen="screen"
      :totalResults="totalAccounts"
      :totalDistinctResults="totalDistinctAccounts"
      :filters="filters"
      :loading="loading"
    ></missing-accounts-info>
    <v-data-table
      fixed-header
      :class="{
        'all-selected': allSelected,
        'scrollable-table__medium': true,
        'table-header_align': true
      }"
      :headers="headersSelected"
      :items="accounts"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAccounts"
      show-select
      item-key="tableId"
      :single-select="false"
      v-model="selected"
      :footer-props="{
        'items-per-page-options': rowsPerPage,
        disablePagination: loading,
        disableItemsPerPage: loading
      }"
    >
      <template v-if="allSelected" v-slot:[`header.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled />
      </template>
      <template v-if="allSelected" v-slot:[`item.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled />
      </template>
      <template v-slot:progress>
        <v-progress-linear
          :color="theme.progress"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <cell-clipboard :text="item.id" />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <td style="display: flex;">
          {{ item.status }}
          <v-img
            style="max-inline-size: 100%; block-size: auto; cursor: pointer"
            class="ml-2"
            v-if="screen === 'feeReview' && item.billingStatusNote"
            @click="showNoteDialog(item.billingStatusNote)"
            src="@/assets/notes.png"
            width="24"
            contain
          ></v-img>
        </td>
      </template>
      <template v-slot:[`item.aum`]="{ item }">
        <span @click="showAumData(item)">
          <cell-monetary-value class="link-text" :value="item.aum" />
        </span>
      </template>
      <template v-slot:[`item.aumDate`]="{ item }">
        <date-cell :value="item.aumDate" />
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span>
          <cell-monetary-value :value="item.amount" />
        </span>
      </template>
      <template v-slot:[`item.priorUnpaid`]="{ item }">
        <span>
          <cell-monetary-value :value="item.priorUnpaid" />
        </span>
      </template>
      <template v-slot:[`item.billingStartDate`]="{ item }">
        <date-cell :value="item.billingStartDate" />
      </template>
      <template v-slot:[`item.billingEndDate`]="{ item }">
        <date-cell :value="item.billingEndDate" />
      </template>
      <template v-slot:[`item.billingRunType`]="{ item }">
        <span v-if="item.billingRunType">
          {{ formatBillingRunType(item.billingRunType) }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getBillingPeriodIntFromDate } from "@/utils/period-util.js";
import { equalObjects } from "@/utils/object-operations.js";
import { formatBillingRunType } from "@/utils/format-util.js";
import { mapState, mapActions } from "vuex";

import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import BillingStatusNoteDetails from "@/components/dialogs/BillingStatusNoteDetails.vue";
import AccountAumDetails from "@/components/dialogs/AccountAumDetails.vue";
import DateCell from "@/components/common/DateCell.vue";
import MissingAccountsInfo from "@/components/filters/MissingAccountsInfo.vue";

export default {
  components: {
    CellClipboard,
    CellMonetaryValue,
    RunningDialog,
    InfoDialog,
    ErrorDialog,
    BillingStatusNoteDetails,
    AccountAumDetails,
    DateCell,
    MissingAccountsInfo,
  },
  props: [
    "screen",
    "headersSelected",
    "pagination",
    "accounts",
    "totalAccounts",
    "totalDistinctAccounts",
    "selectedAccounts",
    "filters",
    "allSelected",
    "loading",
    "theme",
    "refresh",
    "refreshOrCut",
  ],
  emits: ["update:pagination", "update:selectedAccounts"],
  data: () => ({
    showAumDetails: false,
    accountAumData: null,
    gettingAumInfo: false,
    showNoAumMessage: false,
    noAumMessage: "",
    aumError: false,
    selectedNote: null,
    showNoteDetails: false,
  }),
  computed: {
    ...mapState("userConfig", ["rowsPerPage"]),
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.$emit("update:pagination", newValue);
      },
    },
    selected: {
      get() {
        return this.selectedAccounts;
      },
      set(newValue) {
        this.$emit("update:selectedAccounts", newValue);
      },
    },
  },
  watch: {
    options: {
      deep: true,
      handler(val, oldVal) {
        if (!this.$cookies.isKey("token")) {
          return;
        }
        if (
          !equalObjects(val.sortBy, oldVal.sortBy) ||
          !equalObjects(val.sortDesc, oldVal.sortDesc)
        ) {
          this.refresh();
        } else if (val.page !== oldVal.page && val.page) {
          this.refresh();
        } else if (val.itemsPerPage !== oldVal.itemsPerPage) {
          this.refreshOrCut(val.itemsPerPage);
        }
      },
    },
  },
  methods: {
    ...mapActions("billingRecordsCalculations", [
      "getFirstAndLastSleeveDateForAccount",
      "getAccountAumData",
    ]),
    formatBillingRunType(type) {
      return formatBillingRunType(type);
    },
    async showAumData(item) {
      this.gettingAumInfo = true;
      let result = await this.getAccountAumData({
        accountId: item.id,
        billingPeriod: getBillingPeriodIntFromDate(item.aumDate)
      });
      if (result === false) {
        this.gettingAumInfo = false;
        this.aumError = true;
        return;
      }
      if (result.notFound) {
        this.gettingAumInfo = false;
        this.noAumMessage = result.message;
        this.showNoAumMessage = true;
        return;
      }
      let runs = await this.getFirstAndLastSleeveDateForAccount(item.id);
      if (!runs) {
        this.gettingAumInfo = false;
        this.aumError = true;
        return;
      }
      let firstPeriod = runs.firstSleevePeriod;
      let lastPeriod = runs.lastSleevePeriod;
      if (!firstPeriod || !lastPeriod) {
        this.gettingAumInfo = false;
        this.aumError = true;
        return;
      }

      this.accountAumData = {
        ...result,
        accountName: item.name,
        billingType: item.billingType,
        firstPeriod: firstPeriod,
        lastPeriod: lastPeriod
      };
      this.gettingAumInfo = false;
      this.showAumDetails = true;
    },
    showNoteDialog(note) {
      this.selectedNote = note;
      this.showNoteDetails = true;
    },
  }
};
</script>

<style>
/*Light Theme*/
/*rows*/
.all-selected.theme--light.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--light.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}

/*Dark Theme*/
/*rows*/
.all-selected.theme--dark.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--dark.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}
</style>
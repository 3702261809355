<template>
  <td :colspan="headers.length">
    <v-data-table
      fixed-header
      class="scrollable-table__medium"
      :headers="feeHeaders"
      :items="account.fees"
      item-key="name"
      single-expand
      show-expand
      :expanded.sync="expandedItems"
      @item-expanded="expandFees"
      :hide-default-footer="rowNumberCheck(account.fees)"
      :footer-props="{ 'items-per-page-options': rowsPerPage }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <td>
          {{ mapFeeType(item.name) }}
        </td>
      </template>
      <template v-slot:[`item.effectiveRate`]="{ item }">
        <td class="no-wrap">
          {{ getFeeTypeEffectiveRate(item) }}
        </td>
      </template>
      <template v-slot:[`item.aumBasis`]="{ item }">
        <cell-monetary-value :value="getFeeTypeAumBasis(item)" />
      </template>
      <template v-slot:[`item.aum`]="{}">
        <cell-monetary-value :value="getFeeTypeAum(account)" />
      </template>
      <template v-slot:[`item.fee`]="{ item }">
        <cell-monetary-value-format :value="getFeeTypeFee(item)" />
      </template>
      <template v-slot:[`item.topTierRate`]="{ item }">
        <td class="no-wrap">
          {{ getFeeTypeTopTierRate(item) }}
        </td>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <sleeve-table
          :headers="headers"
          :feeType="item"
          :expandedSleeves.sync="sleeves"
          :refreshAccount="refreshAccount"
        ></sleeve-table>
      </template>
    </v-data-table>
  </td>
</template>

<script>
import { mapState } from "vuex";
import {
  getFeeTypeEffectiveRate,
  getFeeTypeAumBasis,
  getFeeTypeAum,
  getFeeTypeFee,
  getFeeTypeTopTierRate,
} from "@/utils/billing-records.js";
import { mapFeeTypeValueToDisplayName } from "@/utils/mapping-util.js";

import SleeveTable from "./SleeveTable.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import CellMonetaryValueFormat from "@/components/common/CellMonetaryValueFormat.vue";

export default {
  components: {
    SleeveTable,
    CellMonetaryValue,
    CellMonetaryValueFormat,
  },
  props: [
    "headers",
    "account",
    "expandedFees",
    "expandedSleeves",
    "refreshAccount",
  ],
  emits: ["update:expandedFees", "update:expandedSleeves"],
  data: () => ({}),
  computed: {
    ...mapState("feeSchedule", ["billingFeeTypes"]),
    ...mapState("userConfig", ["rowsPerPage", "minRowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["feeHeaders"]),
    expandedItems: {
      get() {
        return this.expandedFees;
      },
      set(newValue) {
        this.$emit("update:expandedFees", newValue);
      }
    },
    sleeves: {
      get() {
        return this.expandedSleeves;
      },
      set(newValue) {
        this.$emit("update:expandedSleeves", newValue);
      }
    },
  },
  methods: {
    rowNumberCheck(array) {
      if (array.length <= this.minRowsPerPage) {
        return true;
      } else {
        return false;
      }
    },
    expandFees() {
      this.sleeves = [];
    },
    getFeeTypeEffectiveRate(fee) {
      return getFeeTypeEffectiveRate(fee);
    },
    getFeeTypeAumBasis(fee) {
      return getFeeTypeAumBasis(fee);
    },
    getFeeTypeAum(fee) {
      return getFeeTypeAum(fee);
    },
    getFeeTypeFee(fee) {
      return getFeeTypeFee(fee);
    },
    getFeeTypeTopTierRate(fee) {
      return getFeeTypeTopTierRate(fee);
    },
    mapFeeType(feeType) {
      return mapFeeTypeValueToDisplayName(feeType, this.billingFeeTypes);
    },
  }
};
</script>

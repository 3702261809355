function extractAccountAttribute(account, attributeName) {
  let attributes = account ? account.attributes : null;

  if (!attributes) {
    return "";
  }

  let matchedAttribute = attributes.filter(
    (attr) => attr.attributeName == attributeName
  );

  if (matchedAttribute && matchedAttribute.length > 0) {
    return matchedAttribute[0].attributeValue;
  } else {
    return "";
  }
}

function mapFeeTypeValueToDisplayName(feeTypeValue, feeTypes) {
  let index = feeTypes.findIndex(fee => fee.value === feeTypeValue);
  if (index === -1) {
    return feeTypeValue;
  } else {
    return feeTypes[index].displayName;
  }
}

module.exports = {
  extractAccountAttribute,
  mapFeeTypeValueToDisplayName,
};

function getRowsPerPageForLength(numOfSelected, rowsPerPage) {
  let i = 0;
  let tempRowsPerPage = rowsPerPage[i];
  while (numOfSelected > tempRowsPerPage && ++i < rowsPerPage.length) {
    tempRowsPerPage = rowsPerPage[i];
  }
  return tempRowsPerPage;
}

function getFeeTypeEffectiveRate(fee) {
  let effRate = "";
  let i = 0;
  while (effRate == "" && i < fee.sleeves.length) {
    let sleeve = fee.sleeves[i++];
    let j = 0;
    while (effRate == "" && j < sleeve.records.length) {
      let billingRecord = sleeve.records[j++].billingRecord;
      if (billingRecord.activeBillingRecord) {
        effRate = getPercentageFromNumber(billingRecord.averageFeePercent) + " %";
      }
    }
  }
  return effRate;
}

function getFeeTypeAumBasis(fee) {
  let activeBillingRecords = getActiveBillingRecords(fee);
  if (activeBillingRecords.length > 0) {
    return activeBillingRecords[0].entityAumBasis;
  } else {
    return null;
  }
}

function getFeeTypeAum(account) {
  if (account && account.totalAumAndDate) {
    let aum = account.totalAumAndDate.aum;
    return aum;
  } else {
    return null;
  }
}

function getFeeTypeFee(fee) {
  let activeBillingRecords = getActiveBillingRecords(fee);

  let totalFee = 0;
  activeBillingRecords.forEach((record) => {
    totalFee += record.thisPeriodNetFeeAmount;
  });
  return totalFee;
}

function getFeeTypeTopTierRate(fee) {
  let maxTier = -1;

  const sleeves = fee.sleeves;
  sleeves.forEach((sleeve) => {
    const records = sleeve.records;
    records.forEach((record) => {
      const { billingRecord, schedule } = record;
      const { resultsBlob } = billingRecord;

      if (resultsBlob) {
        var { activeTiers, sortedTiers } = resultsBlob;
      }
      if (schedule) {
        var { billingFeeTiers } = schedule;
      }

      const maxValReducer = (prev, curr) =>
        prev.rateOrAmount > curr.rateOrAmount ? prev : curr;

      if (activeTiers && activeTiers.length > 0) {
        maxTier = activeTiers.reduce(maxValReducer).rateOrAmount;
      } else if (sortedTiers && sortedTiers.length > 0) {
        maxTier = sortedTiers.reduce(maxValReducer).rateOrAmount;
      } else if (billingFeeTiers && billingFeeTiers.length > 0) {
        maxTier = billingFeeTiers.reduce(maxValReducer).rateOrAmount;
      } else {
        console.log("getFeeTypeTopTierRate not calculated");
        return "";
      }
    });
  });

  if (maxTier == -1) {
    console.log("getFeeTypeTopTierRate not calculated");
    return "";
  } else {
    return getPercentageFromNumber(maxTier) + " %";
  }
}

/*
function getAccountActiveBillingRecords(account) {
  let activeBillingRecords = [];

  const fees = account.fees;
  fees.forEach((fee) => {
    let sleeves = fee.sleeves;
    sleeves.forEach((sleeve) => {
      let records = sleeve.records;
      records.forEach((record) => {
        const billingRecord = record.billingRecord;

        if (
          billingRecord.activeBillingRecord &&
          !recordInArray(activeBillingRecords, billingRecord)
        ) {
          activeBillingRecords.push(billingRecord);
        }
      });
    });
  });

  return activeBillingRecords;
}
*/

function getActiveBillingRecords(fee) {
  let activeBillingRecords = [];

  let sleeves = fee.sleeves;
  sleeves.forEach((sleeve) => {
    let records = sleeve.records;
    records.forEach((record) => {
      const billingRecord = record.billingRecord;

      if (
        billingRecord.activeBillingRecord &&
        !recordInArray(activeBillingRecords, billingRecord)
      ) {
        activeBillingRecords.push(billingRecord);
      }
    });
  });

  return activeBillingRecords;
}

function recordInArray(array, record) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].billingEntityId == record.billingEntityId) {
      return true;
    }
  }
  return false;
}

function getScheduleName(item) {
  if (!item || !item.schedule || !item.schedule.name) {
    return "";
  }
  return item.schedule.name;
}

function getInheritedFrom(schedule) {
  if (schedule && schedule.entity1 != null) {
    return schedule.entity1.name;
  } else {
    return "";
  }
}

function getPercentageFromNumber(number) {
  return (number * 100).toFixed(4);
}

module.exports = {
  getRowsPerPageForLength,
  getFeeTypeEffectiveRate,
  getFeeTypeAumBasis,
  getFeeTypeAum,
  getFeeTypeFee,
  getFeeTypeTopTierRate,
  getScheduleName,
  getInheritedFrom,
  getPercentageFromNumber
};

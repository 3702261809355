<template>
  <div class="ml-4">
    <search-filter
      ref="searchFilter"
      :headers="headersWithoutIgnoredValues"
      :multiSelectColumns="multiSelectColumns"
      :columnsWithPredicates="columnsWithPredicates"
      :billingFeeTypes="billingFeeTypes"
      :dropDownValues="dropDownValues"
      :isPredicates="isPredicates"
      :containsPredicates="containsPredicates"
      :inGroupPredicates="inGroupPredicates"
      :hasPredicates="hasPredicates"
      :doesNotHavePredicates="doesNotHavePredicates"
      :dateColumns="dateColumns"
      :filters="filters"
      :results="results"
      :totalResults="totalResults"
      :totalDistinctResults="totalDistinctResults"
      :theme="theme"
      :showCalendarChip="false"
      :showActiveAccountsChip="false"
      :quickSearchParam="quickSearchTermination"
      :refresh="refresh"
      :setQuickSearchParam="setQuickSearchTermination"
      :resetQuickSearchParam="resetQuickSearchTermination"
      :loadPrepopulatedData="loadPrepopulatedData"
    ></search-filter>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SearchFilter from "./SearchFilter.vue";

export default {
  props: [
    "headers",
    "theme",
    "results",
    "totalResults",
    "totalDistinctResults",
    "refresh"
  ],
  components: {
    SearchFilter
  },
  data: () => ({
    multiSelectColumns: [
      "status",
      "billingType",
      "accountStatus",
      "billableStatus",
      "custodian"
    ],
    ignoredHeaderValues: [
      "aum",
      "aumDate",
      "brokerageAccountNumber",
      "billingRunType",
      "priorUnpaid",
      "amount"
    ],
    dateColumns: ["billingStartDate", "billingEndDate"],
    columnsWithPredicates: [
      "id",
      "name",
      "repCode",
      "paidBy",
      "billingFeeType"
    ],
    isPredicates: ["id", "name", "repCode", "paidBy"],
    containsPredicates: ["id", "name", "repCode", "paidBy"],
    inGroupPredicates: ["id", "name", "paidBy"],
    hasPredicates: ["billingFeeType"],
    doesNotHavePredicates: ["billingFeeType"]
  }),
  computed: {
    ...mapState("filters", ["terminationFilters", "quickSearchTermination"]),
    ...mapState("prepopulatedData", [
      "statuses",
      "feeFrequencies",
      "accountStatuses",
      "billingFeeTypes",
      "billables",
      "custodians"
    ]),
    headersWithoutIgnoredValues() {
      let filteredHeaders = this.headers.filter(
        x => !this.ignoredHeaderValues.includes(x.value)
      );
      filteredHeaders.unshift({
        text: "Quick search",
        value: "quickSearch"
      });
      filteredHeaders.push({
        text: "Fee Schedule Type",
        value: "billingFeeType"
      });
      return filteredHeaders;
    },
    filters: {
      get() {
        return this.terminationFilters;
      },
      set(newValue) {
        this.setTerminationFilters(newValue);
      }
    }
  },
  methods: {
    ...mapMutations("filters", [
      "setTerminationFilters",
      "setQuickSearchTermination",
      "resetQuickSearchTermination"
    ]),
    ...mapActions("prepopulatedData", ["loadFeeReviewPrepopulatedData"]),
    dropDownValues(column) {
      switch (column) {
        case "status":
          return this.statuses;
        case "billingType":
          return this.feeFrequencies;
        case "accountStatus":
          return this.accountStatuses;
        case "custodian":
          return this.custodians;
        case "billingFeeType":
          return this.billingFeeTypes;
        case "billableStatus":
          return this.billables;
        default:
          return [];
      }
    },
    async loadPrepopulatedData() {
      await this.loadFeeReviewPrepopulatedData();
    }
  }
};
</script>

<template>
  <v-dialog v-model="show" max-width="600" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline">
          Customize columns
        </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="5" class="zero-padding-top">
            <v-card-text
              class="zero-padding-top zero-padding-bottom text-center"
            >
              Available Columns
            </v-card-text>
            <v-list
              dense
              class="scrolling-list"
              :key="availableListComponentKey"
            >
              <v-list-item-group
                v-model="selectedInAvailableColumns"
                :color="primaryColor"
              >
                <v-list-item
                  class="list-item"
                  v-for="column in availableColumns"
                  :key="column.value"
                >
                  <v-list-item-title>{{ column.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="1" align-self="center">
            <v-btn
              class="arrow"
              :disabled="selectedInAvailableColumns == null"
              icon
              small
              @click="moveColumnToSelected"
            >
              <v-icon> mdi-arrow-right-bold </v-icon>
            </v-btn>
            <v-btn
              class="arrow"
              :disabled="selectedInSelectedColumns == null"
              icon
              small
              @click="moveColumnToAvailable"
            >
              <v-icon> mdi-arrow-left-bold </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5" class="zero-padding-top">
            <v-card-text
              class="zero-padding-top zero-padding-bottom text-center"
            >
              Selected Columns
            </v-card-text>
            <v-list
              dense
              class="scrolling-list"
              :key="selectedListComponentKey"
            >
              <v-list-item-group
                v-model="selectedInSelectedColumns"
                :color="primaryColor"
              >
                <v-list-item
                  class="list-item"
                  v-for="column in selectedColumns"
                  :key="column.value"
                >
                  <v-list-item-title>{{ column.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="1" align-self="center">
            <v-btn
              class="arrow"
              :disabled="selectedInSelectedColumns == null"
              icon
              small
              @click="moveColumnUp"
            >
              <v-icon> mdi-arrow-up-bold </v-icon>
            </v-btn>
            <v-btn
              class="arrow"
              :disabled="selectedInSelectedColumns == null"
              icon
              small
              @click="moveColumnDown"
            >
              <v-icon> mdi-arrow-down-bold </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :style="colorTheme.textColor"
            :color="colorTheme.buttonWhite"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn :color="colorTheme.button" dark @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["showDialog", "defaultHeaders", "savedHeaders", "nameOfSetting"],
  emits: ["update:showDialog", "update:savedHeaders"],
  data: () => ({
    availableColumns: [],
    selectedColumns: [],
    selectedInAvailableColumns: null,
    selectedInSelectedColumns: null,
    selectedListComponentKey: 0,
    availableListComponentKey: 0
  }),
  computed: {
    ...mapState("userConfig", ["primaryColor", "colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.reset();
        this.organizeHeadersInLists();
      }
    }
  },
  methods: {
    ...mapActions("columnConfigurator", ["setHeaders", "saveHeaders"]),
    organizeHeadersInLists() {
      this.savedHeaders.forEach(column => {
        if (column.selected) {
          this.selectedColumns.push(column);
        } else {
          this.availableColumns.push(column);
        }
      });
    },
    reset() {
      this.selectedColumns = [];
      this.availableColumns = [];
      this.selectedInAvailableColumns = null;
      this.selectedInSelectedColumns = null;
      this.availableListComponentKey = 0;
      this.selectedListComponentKey = 0;
    },
    moveColumnUp() {
      if (
        this.selectedInSelectedColumns !== null &&
        this.selectedInSelectedColumns > 0 &&
        this.selectedInSelectedColumns < this.selectedColumns.length
      ) {
        let x = this.selectedInSelectedColumns;
        let y = this.selectedInSelectedColumns - 1;
        this.selectedColumns[x] = this.selectedColumns.splice(
          y,
          1,
          this.selectedColumns[x]
        )[0];
        this.selectedListComponentKey += 1;
        this.selectedInSelectedColumns = this.selectedInSelectedColumns - 1;
      }
    },
    moveColumnDown() {
      if (
        this.selectedInSelectedColumns !== null &&
        this.selectedInSelectedColumns >= 0 &&
        this.selectedInSelectedColumns < this.selectedColumns.length - 1
      ) {
        let x = this.selectedInSelectedColumns;
        let y = this.selectedInSelectedColumns + 1;
        this.selectedColumns[x] = this.selectedColumns.splice(
          y,
          1,
          this.selectedColumns[x]
        )[0];
        this.selectedListComponentKey += 1;
        this.selectedInSelectedColumns = this.selectedInSelectedColumns + 1;
      }
    },
    moveColumnToAvailable() {
      if (this.selectedInSelectedColumns !== null) {
        let selected = Object.assign(
          {},
          this.selectedColumns[this.selectedInSelectedColumns]
        );
        this.selectedColumns = this.selectedColumns.filter(
          column => column.value != selected.value
        );
        selected.selected = false;
        this.availableColumns.push(selected);
        this.selectedInSelectedColumns = null;
        this.selectedInAvailableColumns = null;
        this.selectedListComponentKey += 1;
        this.availableListComponentKey += 1;
      }
    },
    moveColumnToSelected() {
      if (this.selectedInAvailableColumns !== null) {
        let selected = Object.assign(
          {},
          this.availableColumns[this.selectedInAvailableColumns]
        );
        this.availableColumns = this.availableColumns.filter(
          column => column.value != selected.value
        );
        selected.selected = true;
        this.selectedColumns.push(selected);
        this.selectedInSelectedColumns = null;
        this.selectedInAvailableColumns = null;
        this.selectedListComponentKey += 1;
        this.availableListComponentKey += 1;
      }
    },
    close() {
      this.$emit("update:showDialog", false);
    },
    async save() {
      //Update headers to new order and selection
      let newHeaders = [];
      this.selectedColumns.forEach(column => {
        newHeaders.push(column);
      });
      this.availableColumns.forEach(column => {
        newHeaders.push(column);
      });

      if (newHeaders.length > 0) {
        await this.saveHeaders({
          nameOfSetting: this.nameOfSetting,
          settingValue: newHeaders
        });
        this.$emit("update:savedHeaders", newHeaders);
      }
      this.close();
    }
  },
  async mounted() {
    let headers = await this.setHeaders(this.nameOfSetting);
    if (headers) {
      this.$emit("update:savedHeaders", JSON.parse(headers.settingValue));
    } else {
      this.$emit("update:savedHeaders", this.defaultHeaders);
    }
  }
};
</script>

<style scoped>
.scrolling-list {
  height: 300px;
  border: 1px solid black;
  overflow-y: scroll;
}
.list-item {
  padding: 0 0.5em;
  min-height: 2em;
}
.zero-padding-top {
  padding-top: 0;
}
.zero-padding-bottom {
  padding-bottom: 0;
}
.arrow {
  color: teal !important;
}
</style>

<template>
  <v-dialog v-model="show" persistent width="unset">
    <v-card :color="colorTheme.info" dark>
      <div>
        <v-card-text class="text-center white--text">
          {{ message }}
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            :color="colorTheme.progressWhite"
          ></v-progress-linear>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showDialog", "message"],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  }
};
</script>

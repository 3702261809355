<template>
  <v-container fluid>
    <success-dialog
      :message="runStartedMessage"
      :showDialog.sync="runCalcStartSuccess"
    ></success-dialog>
    <review-fees-and-schedules
      :items="terminationCalculationResults"
      :allSelected="terminationAllSelected"
      :selectedPeriod="billingPeriod"
      :accounts="selectedTerminationAccounts"
      :showBillingRecordsErrorDialog="showBillingRecordsErrorDialog"
      :showCalculationErrorDialog="showCalculationErrorDialog"
      :calculationWarningDialog.sync="calculationWarningDialog"
      :calculationWarningMessage.sync="calculationWarningMessage"
      :totalCalculationResults="totalTerminationCalculationResults"
      :refresh="refresh"
      :refreshOrCut="refreshOrCut"
      :refreshAndModify="refreshAndModify"
      :reRun="reRun"
      :selectAllAccounts="selectAllAccounts"
      @back="back"
      @set-accounts-for-re-run="setTerminationAccountsForReRun"
    ></review-fees-and-schedules>
  </v-container>
</template>

<script>
import { getCurrentPeriod } from "@/utils/period-util.js";
import { mapActions, mapMutations, mapState } from "vuex";
import ReviewFeesAndSchedules from "@/components/billing-records/ReviewFeesAndSchedules.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";

export default {
  emits: ["back"],
  components: {
    ReviewFeesAndSchedules,
    SuccessDialog,
  },
  data: () => ({
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    runStartedMessage: "Running of calculation started. Check calculation status in Fee Review.",
    runCalcStartSuccess: false,
    showBillingRecordsErrorDialog: false,
    showCalculationErrorDialog: false
  }),
  computed: {
    ...mapState("terminationRecords", [
      "terminationCalculationResults",
      "totalTerminationCalculationResults",
      "terminationAccountsForReRun",
      "terminationAccountsForCalculation",
    ]),
    ...mapState("terminationAccounts", [
      "selectedTerminationAccounts",
      "terminationAllSelected"
    ]),
    billingPeriod() {
      return getCurrentPeriod();
    },
  },
  methods: {
    ...mapMutations("terminationRecords", [
      "setTerminationAccountsForReRun",
      "setTerminationCalculationResults"
    ]),
    ...mapActions("terminationRecords", [
      "getTerminationAccountRecords",
      "reRunTermination",
      "createAndViewTerminationBillingRecords",
      "getAllTerminationBillingRecordsPaged"
    ]),
    back() {
      this.$emit("back");
    },
    async reRun(payload) {
      this.calculationWarningMessage = "";
      this.calculationWarningDialog = false;
      let all;
      let accounts;
      if (payload.all) {
        all = this.terminationAllSelected;
        accounts = this.terminationAccountsForCalculation;
      } else {
        all = false;
        accounts = this.terminationAccountsForReRun;
      }
      let result = await this.reRunTermination({
        page: 1,
        pageSize: payload.itemsPerPage,
        all: all,
        accounts: accounts
      });

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showCalculationErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (!result.responseData) {
        this.showCalculationErrorDialog = true;
      }
    },
    async refreshAndModify(payload) {
      let result = await this.getTerminationAccountRecords(payload);
      return result;
    },
    async refreshOrCut(options) {
      let array = this.terminationCalculationResults;
      if (options.itemsPerPage > array.length) {
        await this.refresh(options);
      } else {
        this.setTerminationCalculationResults(
          this.terminationCalculationResults.slice(0, options.itemsPerPage)
        );
      }
    },
    async refresh(options) {
      let result = await this.createAndViewTerminationBillingRecords({
        page: options.page,
        pageSize: options.itemsPerPage,
        all: this.terminationAllSelected
      });

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showBillingRecordsErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (!result.responseData) {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    async selectAllAccounts(options) {
      options.accounts = this.terminationAccountsForCalculation;
      let allAccounts = await this.getAllTerminationBillingRecordsPaged(options);
      return allAccounts;
    }
  }
};
</script>

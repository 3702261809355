<template>
  <td :colspan="headers.length">
    <fee-details-dialog
      :show-dialog.sync="showFeeDetailsDialog"
      :item="selectedBillingRecord"
      :sumOfAdjustments="sumOfAdjustments"
    ></fee-details-dialog>
    <list-of-adjustments
      :showDialog.sync="showAdjustmentDialog"
      :itemToAdjust.sync="itemToAdjust"
      :refreshAccount="refreshAccount"
    ></list-of-adjustments>
    <running-dialog
      message="Setting active billing record..."
      :showDialog.sync="setingActiveRecord"
    ></running-dialog>
    <error-dialog
      message="Can't set active billing record!"
      :showDialog.sync="activeRecordErrorMessage"
    ></error-dialog>
    <v-data-table
      fixed-header
      class="scrollable-table__medium"
      :headers="billingRecordHeaders"
      :items="sleeve.records"
      item-key="name"
      :hide-default-footer="rowNumberCheck(sleeve.records)"
      :footer-props="{
        'items-per-page-options': rowsPerPage
      }"
    >
      <template v-slot:[`item.feeDetails`]="{ item }">
        <v-icon
          small
          class="info-icon"
          @click="showFeeDetails(item.billingRecord)"
        >
          mdi-information-outline
        </v-icon>
      </template>
      <template v-slot:[`item.schedule.name`]="{ item }">
        {{ getScheduleName(item) }}
      </template>
      <template v-slot:[`item.inheritedFrom`]="{ item }">
        <td>
          {{ getInheritedFrom(item.schedule) }}
          <template v-if="item.schedule && item.schedule.entity1">
            <cell-clipboard :text="item.schedule.entity1.id" />
          </template>
        </td>
      </template>
      <template v-slot:[`item.entityAumBasis`]="{ item }">
        <cell-monetary-value :value="item.billingRecord.entityAumBasis" />
      </template>
      <template v-slot:[`item.averageFeePercent`]="{ item }">
        <td class="no-wrap">
          {{ getPercentageFromNumber(item.billingRecord.averageFeePercent) + " %" }}
        </td>
      </template>
      <template v-slot:[`item.entityAum`]="{ item }">
        <cell-monetary-value :value="item.billingRecord.entityAum" />
      </template>
      <template v-slot:[`item.billingRecord.aumDate`]="{ item }">
        <date-cell :value="item.billingRecord.aumDate" />
      </template>
      <template v-slot:[`item.thisPeriodNetFeeAmount`]="{ item }">
        <cell-monetary-value-format
          :value="item.billingRecord.thisPeriodNetFeeAmount"
        />
      </template>
      <template v-slot:[`item.adjustments`]="{ item }">
        <v-btn small @click="adjust(item)">
          Adjust (
          <cell-monetary-value
            :value="sumOfAdjustments(item.billingRecord.adjustments)"
          />
          )
        </v-btn>
      </template>
      <template v-slot:[`item.preferred`]="{ item }">
        <v-icon
          v-if="item.billingRecord.activeBillingRecord == true"
          x-large
          :color="colorTheme.success"
        >
          mdi-check-bold
        </v-icon>
        <v-btn v-else small @click="setAsPreferred(item)">
          Set as preferred
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" :color="colorTheme.alert">
          {{ noBillingRecordsText }}
        </v-alert>
      </template>
    </v-data-table>
  </td>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getScheduleName, getInheritedFrom, getPercentageFromNumber } from "@/utils/billing-records.js";

import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import CellMonetaryValueFormat from "@/components/common/CellMonetaryValueFormat.vue";
import FeeDetailsDialog from "./FeeDetailsDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import ListOfAdjustments from "./ListOfAdjustments.vue";
import DateCell from "@/components/common/DateCell.vue";

export default {
  components: {
    CellClipboard,
    CellMonetaryValue,
    CellMonetaryValueFormat,
    FeeDetailsDialog,
    RunningDialog,
    ErrorDialog,
    ListOfAdjustments,
    DateCell,
  },
  props: ["headers", "sleeve", "expandedSleeve", "refreshAccount"],
  data: () => ({
    showFeeDetailsDialog: false,
    selectedBillingRecord: null,
    showAdjustmentDialog: false,
    itemToAdjust: null,
    setingActiveRecord: false,
    activeRecordErrorMessage: false,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage", "minRowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["billingRecordHeaders"]),
    noBillingRecordsText() {
      return "No billing records";
    },
  },
  methods: {
    ...mapActions("billingRecordsCalculations", ["updateBillingRecords"]),
    sumOfAdjustments(adjustments) {
      if (!adjustments) return 0;
      let sum = 0;
      adjustments.forEach(adj => {
        sum += adj.adjustmentValue;
      });
      return sum;
    },
    rowNumberCheck(array) {
      if (array.length <= this.minRowsPerPage) {
        return true;
      } else {
        return false;
      }
    },
    adjust(item) {
      this.showAdjustmentDialog = true;
      this.itemToAdjust = item.billingRecord;
    },
    async setAsPreferred(item) {
      try {
        let records = this.expandedSleeve.records;
        let currentActive = null;
        let futureActive = Object.assign({}, item.billingRecord);

        for (let i = 0; i < records.length; i++) {
          if (records[i].billingRecord.activeBillingRecord) {
            currentActive = records[i].billingRecord;
            break;
          }
        }
        currentActive.activeBillingRecord = false;
        futureActive.activeBillingRecord = true;
        this.setingActiveRecord = true;
        let resultRecords = await this.updateBillingRecords([
          currentActive,
          futureActive
        ]);
        if (resultRecords) {
          await this.refreshAccount(resultRecords[0]);
        } else {
          this.activeRecordErrorMessage = true;
        }
        this.setingActiveRecord = false;
      } catch (error) {
        this.activeRecordErrorMessage = true;
        console.log(error);
      }
    },
    showFeeDetails(item) {
      this.showFeeDetailsDialog = true;
      this.selectedBillingRecord = item;
    },
    getScheduleName(item) {
      return getScheduleName(item);
    },
    getInheritedFrom(schedule) {
      return getInheritedFrom(schedule);
    },
    getPercentageFromNumber(number) {
      return getPercentageFromNumber(number);
    }
  }
};
</script>

<template>
  <v-container fluid>
    <warning-confirmation-dialog
      :showDialog.sync="modifyRecordsWarning"
      message="Warning! Some data might be wrong. Table will be reloaded."
      @confirm="refreshPage"
    ></warning-confirmation-dialog>
    <running-dialog
      message="Running of calculation is starting."
      :showDialog.sync="runningCalculation"
    ></running-dialog>
    <error-dialog
      message="Billing Records Error! Try again."
      :showDialog.sync="showRecordsErrorDialog"
    ></error-dialog>
    <error-dialog
      message="Calculation Error! Try again."
      :showDialog.sync="showCalcErrorDialog"
    ></error-dialog>
    <warning-dialog
      :message="calcWarningMessage"
      :showDialog.sync="calcWarningDialog"
    ></warning-dialog>
    <v-card-title> Review Fees and Schedules </v-card-title>
    <fee-from-fee-to-filter
      v-if="isFeeResults"
      :multiPeriodActive="multiPeriodActive"
      :refresh="refreshToFirstPage"
      :theme="theme"
    ></fee-from-fee-to-filter>
    <v-card>
      <account-multi-period-table
        v-if="multiPeriodActive"
        :items="itemsMultiPeriod"
        :selected.sync="selectedMultiPeriod"
        :selectedAccountsInPeriod.sync="selectedAccountsInPeriod"
        :loading="loading"
        :options.sync="options"
        :itemsCount="totalCalculationResultsMultiPeriod"
        :expandedAccountsMultiPeriod.sync="expandedAccountsMultiPeriod"
        :expandedAccountsInPeriod.sync="expandedAccountsInPeriod"
        :expandedFees.sync="expandedFees"
        :expandedSleeves.sync="expandedSleeves"
        :resetExpandedAccountsMultiPeriod="resetExpandedAccountsMultiPeriod"
        :resetExpandedAccountsInPeriod="resetExpandedAccountsInPeriod"
        :resetExpandedFees="resetExpandedFees"
        :resetExpandedSleeves="resetExpandedSleeves"
        :refreshAccount="refreshAccount"
        :allSelected="allSelectedAccounts"
        :formatDate="formatDate"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      ></account-multi-period-table>
      <account-table
        v-else
        :items="items"
        :selected.sync="selected"
        :loading="loading"
        :options.sync="options"
        :itemsCount="totalCalculationResults"
        :expandedAccounts.sync="expandedAccounts"
        :expandedFees.sync="expandedFees"
        :expandedSleeves.sync="expandedSleeves"
        :resetExpandedAccounts="resetExpandedAccounts"
        :resetExpandedFees="resetExpandedFees"
        :resetExpandedSleeves="resetExpandedSleeves"
        :formatDate="formatDate"
        :refreshAccount="refreshAccount"
        :allSelected="allSelectedAccounts"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      ></account-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span class="number-effect_space">
          <v-row>
            <v-col cols="12" md="8" style="margin: auto">
              <v-card-text v-if="multiPeriodActive">
                Selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelectedAccountsInPeriods }}
                </span>
                accounts
              </v-card-text>
              <v-card-text v-else>
                Accounts selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelectedAccounts }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                label="ALL"
                v-model="allSelectedAccounts"
                :color="theme.switch"
              ></v-switch>
            </v-col>
          </v-row>
        </span>
      </v-row>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <regular-button
            buttonText="Back"
            :theme="theme"
            :disabled="false"
            :action="back"
          ></regular-button>
          <regular-button
            buttonText="Select None"
            :theme="theme"
            :disabled="false"
            :action="selectNone"
          ></regular-button>
        </span>
        <span>
          <download-export-file
            :items="items"
            :itemsMultiPeriod="itemsMultiPeriod"
            :selectedPeriod="selectedPeriod"
            :accounts="accounts"
            :allSelected="allSelected"
            :multiPeriodActive="multiPeriodActive"
            :totalCalculationResults="totalCalculationResults"
            :totalResultsMultiPeriod="totalCalculationResultsMultiPeriod"
            :selectAllAccounts="selectAllAccounts"
            :selectAllAccountsMultiPeriod="selectAllAccountsMultiPeriod"
            :theme="theme"
          ></download-export-file>
          <v-tooltip top nudge-top="10" max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" :class="{ 'hover-disabled': noneSelected }">
                <v-btn
                  v-bind="attrs"
                  tile
                  class="mb-2 ml-2 mr-2 btn-letter_transform"
                  :color="theme.button"
                  :dark="!reRunDisabled && !noneSelected"
                  @click="reRunCalculation"
                  :disabled="reRunDisabled || noneSelected"
                >
                  Recalculate Fees
                </v-btn>
              </span>
            </template>
            <span v-if="!noneSelected">
              <span v-if="reRunDisabled"> {{ disallowedStatusMessage }} </span>
              <span v-else>
                Delete Existing Billing Records and Run Calculation
              </span>
            </span>
          </v-tooltip>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { getRowsPerPageForLength } from "@/utils/billing-records.js";

import { formatDate } from "@/utils/format-util.js";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import WarningConfirmationDialog from "@/components/dialogs/WarningConfirmationDialog.vue";
import AccountTable from "./AccountTable.vue";
import DownloadExportFile from "./DownloadExportFile.vue";
import AccountMultiPeriodTable from "./AccountMultiPeriodTable.vue";
import FeeFromFeeToFilter from "@/components/filters/billing-records/FeeFromFeeToFilter.vue";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
  components: {
    RunningDialog,
    ErrorDialog,
    WarningDialog,
    WarningConfirmationDialog,
    AccountTable,
    DownloadExportFile,
    AccountMultiPeriodTable,
    FeeFromFeeToFilter,
    RegularButton
  },
  props: [
    "screen",
    "items",
    "itemsMultiPeriod",
    "allSelected",
    "selectedPeriod",
    "allItemsLoaded",
    "accounts",
    "showBillingRecordsErrorDialog",
    "showCalculationErrorDialog",
    "calculationWarningDialog",
    "calculationWarningMessage",
    "totalCalculationResults",
    "totalCalculationResultsMultiPeriod",
    "refresh",
    "refreshOrCut",
    "refreshMultiPeriod",
    "refreshOrCutMultiPeriod",
    "refreshAndModify",
    "reRun",
    "selectAllAccounts",
    "selectAllAccountsMultiPeriod",
    "resetMultiPeriodResults",
    "resetCalculationResults",
  ],
  emits: [
    "back",
    "set-accounts-for-re-run",
    "set-accounts-for-re-run-multi-period",
    "update:showBillingRecordsErrorDialog",
    "update:showCalculationErrorDialog",
    "update:calculationWarningMessage",
    "update:calculationWarningDialog",
    "update:allItemsLoaded",
  ],
  data: () => ({
    disabledReRunButton: true,
    activeEffect: false,
    modifyRecordsWarning: false,
    loading: false,
    runningCalculation: false,
    selected: [],
    selectedMultiPeriod: [],
    selectedAccountsInPeriod: [],
    expandedAccounts: [],
    expandedAccountsMultiPeriod: [],
    expandedAccountsInPeriod: [],
    expandedFees: [],
    expandedSleeves: [],
    calledFromWatcher: false
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "colorThemeAll", "rowsPerPage"]),
    ...mapState("billingRecordsCalculations", [
      "billingRecordsPagination",
      "disallowedStatusesForRun",
      "disallowedStatusMessage",
    ]),
    ...mapState("feeResultsRecords", ["periodRangeFilter"]),
    ...mapState("feeResultsAccounts", ["selectedAccounts"]),
    ...mapState("reviewFeesSchedules", ["selectedAllAccounts"]),
    theme() {
      if (this.allSelectedAccounts) {
        return this.colorThemeAll;
      } else {
        return this.colorTheme;
      }
    },
    allSelectedAccounts: {
      get() {
        return this.selectedAllAccounts;
      },
      set(newValue) {
        this.setSelectedAllAccounts(newValue);
      }
    },
    showRecordsErrorDialog: {
      get() {
        return this.showBillingRecordsErrorDialog;
      },
      set(newValue) {
        this.$emit("update:showBillingRecordsErrorDialog", newValue);
      },
    },
    showCalcErrorDialog: {
      get() {
        return this.showCalculationErrorDialog;
      },
      set(newValue) {
        this.$emit("update:showCalculationErrorDialog", newValue);
      },
    },
    allAccountsLoaded: {
      get() {
        return this.allItemsLoaded;
      },
      set(newValue) {
        this.$emit("update:allItemsLoaded", newValue);
      },
    },
    options: {
      get() {
        return this.billingRecordsPagination;
      },
      set(newValue) {
        this.setBillingRecordsPagination(newValue);
      },
    },
    calcWarningMessage: {
      get() {
        return this.calculationWarningMessage;
      },
      set(newValue) {
        this.$emit("update:calculationWarningMessage", newValue);
      },
    },
    calcWarningDialog: {
      get() {
        return this.calculationWarningDialog;
      },
      set(newValue) {
        this.$emit("update:calculationWarningDialog", newValue);
      },
    },
    page: {
      get() {
        return this.options.page;
      },
      set(newValue) {
        this.options.page = newValue;
      },
    },
    noneSelected() {
      if (this.multiPeriodActive) {
        return this.totalSelectedAccountsInPeriods == 0;
      } else {
        return this.totalSelectedAccounts == 0;
      }
    },
    reRunDisabled() {
      if (this.multiPeriodActive) {
        return (this.statusNotAllowed(this.selectedAccountsInPeriod));
      } else {
        return (this.statusNotAllowed(this.selected));
      }
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    expandedAccount() {
      return this.expandedAccounts[0];
    },
    expandedAccountMultiPeriod() {
      return this.expandedAccountsMultiPeriod[0];
    },
    expandedAccountInPeriod() {
      return this.expandedAccountsInPeriod[0];
    },
    expandedFee() {
      return this.expandedFees[0];
    },
    expandedSleeve() {
      return this.expandedSleeves[0];
    },
    multiPeriodActive() {
      return (
        this.periodRangeFilter !== undefined &&
        this.periodRangeFilter !== null &&
        this.periodRangeFilter.feeFrom !== undefined &&
        this.periodRangeFilter.feeFrom !== null &&
        this.periodRangeFilter.feeTo !== undefined &&
        this.periodRangeFilter.feeTo !== null &&
        this.isFeeResults
      );
    },
    totalSelectedAccounts() {
      if (this.allSelectedAccounts) {
        return this.totalCalculationResults;
      } else {
        return this.selected.length;
      }
    },
    totalSelectedAccountsInPeriods() {
      let accounts = [];
      if (this.allSelectedAccounts) {
        return this.totalCalculationResultsMultiPeriod;
      } else {
        this.selectedAccountsInPeriod.forEach(acc => {
          if (!accounts.includes(acc.accountId)) {
            accounts.push(acc.accountId);
          }
        });
        return accounts.length;
      }
    },
    isFeeResults() {
      return this.screen === "feeResults";
    }
  },
  watch: {
    page() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refreshPage();
    },
    pageSize() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refreshOrCutPage();
    },
    totalSelectedAccounts() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    totalSelectedAccountsInPeriods() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      let accountsForReRun = val.map(account => {
        return {
          id: account.accountId,
          billingRunType: account.billingRunType,
          billingPeriod: account.billingPeriod
        };
      });
      this.$emit("set-accounts-for-re-run", accountsForReRun);
    },
    selectedMultiPeriod(newVal, oldVal) {
      if (this.calledFromWatcher) {
        this.calledFromWatcher = false;
        return;
      }

      let items = [];
      if (this.allAccountsLoaded.length > 0) {
        items = this.allAccountsLoaded;
      } else {
        items = this.itemsMultiPeriod;
      }

      let newSelected = [];
      items.forEach(item => {
        if (this.arrayIncludesAccount(newVal, item)) {
          // All accounts in period for this account should be selected
          item.accountsInPeriod.forEach(element => {
            newSelected.push(element);
          });
        } else if (this.itemRemovedFromSelected(newVal, oldVal, item)) {
          // None of accounts in period for this account should be selected
        } else {
          // Only accounts in period that are already selected should stay selected
          this.selectedAccountsInPeriod.forEach(a => {
            if (
              a.accountId == item.accountId &&
              a.billingRunType == item.billingRunType
            ) {
              newSelected.push(a);
            }
          });
        }
      });

      this.calledFromWatcher = true;
      this.selectedAccountsInPeriod = newSelected;
    },
    selectedAccountsInPeriod(newVal) {
      let accountsInPeriodForReRun = newVal.map(acc => {
        return {
          id: acc.accountId,
          billingRunType: acc.billingRunType,
          billingPeriod: acc.billingPeriod
        };
      });
      this.$emit("set-accounts-for-re-run-multi-period", accountsInPeriodForReRun);

      if (this.calledFromWatcher) {
        this.calledFromWatcher = false;
        return;
      }

      let accountsWithRunTypes = [];
      newVal.forEach(accInPeriod => {
        if (
          !accountsWithRunTypes.includes(
            accInPeriod.accountId + accInPeriod.billingRunType
          )
        ) {
          accountsWithRunTypes.push(
            accInPeriod.accountId + accInPeriod.billingRunType
          );
        }
      });

      let items = [];
      if (this.allAccountsLoaded.length > 0) {
        items = this.allAccountsLoaded;
      } else {
        items = this.itemsMultiPeriod;
      }

      let newSelectedMultiPeriod = [];
      items.forEach(item => {
        // Only accounts with all periods selected should be selected
        if (
          accountsWithRunTypes.includes(item.accountId + item.billingRunType) &&
          this.allSelectedForAccount(item)
        ) {
          newSelectedMultiPeriod.push(item);
        }
      });

      this.calledFromWatcher = true; // avoid infinite calls
      this.selectedMultiPeriod = newSelectedMultiPeriod;
    }
  },
  methods: {
    ...mapMutations("billingRecordsCalculations", [
      "setBillingRecordsPagination",
    ]),
    ...mapMutations("reviewFeesSchedules", [
      "setSelectedAllAccounts",
      "resetSelectedAllAccounts"
    ]),
    async refreshAccount(record) {
      try {
        this.loading = true;
        let payload = { modifiedRecord: record };
        if (this.multiPeriodActive) {
          payload = { ...payload, multiPeriodActive: true };
        }
        let result = await this.refreshAndModify(payload);
        this.loading = false;
        if (!result) {
          this.modifyRecordsWarning = true;
          return false;
        }
        this.refreshExpandedItems();
        return result;
      } catch (err) {
        this.modifyRecordsWarning = true;
      }
    },
    refreshExpandedItems() {
      let newAccountMultiPeriod;
      let newAccount;
      let newFee;
      let newSleeve;
      if (this.multiPeriodActive) {
        for (let i = 0; i < this.itemsMultiPeriod.length; i++) {
          let acc = this.itemsMultiPeriod[i];
          if (this.sameAccounts(acc, this.expandedAccountMultiPeriod)) {
            newAccountMultiPeriod = acc;
            this.expandedAccountsMultiPeriod = [newAccountMultiPeriod];
            break;
          }
        }

        for (let i = 0; i < newAccountMultiPeriod.accountsInPeriod.length; i++) {
          let acc = newAccountMultiPeriod.accountsInPeriod[i];
          if (this.sameAccounts(acc, this.expandedAccountInPeriod)) {
            newAccount = acc;
            this.expandedAccountsInPeriod = [newAccount];
            break;
          }
        }
      } else {
        for (let i = 0; i < this.items.length; i++) {
          let acc = this.items[i];
          if (acc.accountId == this.expandedAccount.accountId) {
            newAccount = acc;
            this.expandedAccounts = [newAccount];
            break;
          }
        }
      }

      for (let i = 0; i < newAccount.fees.length; i++) {
        let fee = newAccount.fees[i];
        if (fee.name == this.expandedFee.name) {
          newFee = fee;
          this.expandedFees = [newFee];
          break;
        }
      }

      for (let i = 0; i < newFee.sleeves.length; i++) {
        let sleeve = newFee.sleeves[i];
        if (sleeve.sleeveId == this.expandedSleeve.sleeveId) {
          newSleeve = sleeve;
          this.expandedSleeves = [newSleeve];
          break;
        }
      }
    },
    sameAccounts(acc1, acc2) {
      return (
        acc1.accountId == acc2.accountId &&
        acc1.billingRunType == acc2.billingRunType &&
        acc1.billingPeriod == acc2.billingPeriod
      );
    },
    arrayIncludesAccount(array, account) {
      let found = false;
      array.forEach(a => {
        if (
          a.accountId === account.accountId &&
          a.billingRunType === account.billingRunType
        ) {
          found = true;
        }
      });
      return found;
    },
    itemRemovedFromSelected(newArray, oldArray, account) {
      if (
        this.arrayIncludesAccount(oldArray, account) &&
        !this.arrayIncludesAccount(newArray, account)
      ) {
        return true;
      } else {
        return false;
      }
    },
    allSelectedForAccount(account) {
      let totalCount = account.accountsInPeriod.length;
      let selected = this.selectedAccountsInPeriod.filter(
        acc =>
          acc.accountId === account.accountId &&
          acc.billingRunType === account.billingRunType
      );
      let selectedCount = selected.length;

      if (selectedCount == totalCount) {
        return true;
      } else {
        return false;
      }
    },
    selectNone() {
      if (this.selected.length > 0) {
        this.selected = [];
      }
      if (this.selectedMultiPeriod.length > 0) {
        this.selectedMultiPeriod = [];
      }
      if (this.selectedAccountsInPeriod.length > 0) {
        this.selectedAccountsInPeriod = [];
      }
      this.allSelectedAccounts = false;
    },
    resetExpandedAccounts() {
      this.expandedAccounts = [];
    },
    resetExpandedAccountsMultiPeriod() {
      this.expandedAccountsMultiPeriod = [];
    },
    resetExpandedAccountsInPeriod() {
      this.expandedAccountsInPeriod = [];
    },
    resetExpandedFees() {
      this.expandedFees = [];
    },
    resetExpandedSleeves() {
      this.expandedSleeves = [];
    },
    resetAllExpanded() {
      this.resetExpandedAccountsMultiPeriod();
      this.resetExpandedAccountsInPeriod();
      this.resetExpandedAccounts();
      this.resetExpandedFees();
      this.resetExpandedSleeves();
    },
    async refreshToFirstPage() {
      this.resetMultiPeriodResults();
      this.resetCalculationResults();
      if (this.page == 1) {
        this.refreshPage();
      } else {
        this.page = 1;
      }
    },
    async refreshPage() {
      this.loading = true;
      this.resetAllExpanded();
      if (this.multiPeriodActive) {
        await this.refreshMultiPeriod(this.options);
      } else {
        await this.refresh(this.options);
      }
      this.loading = false;
    },
    async refreshOrCutPage() {
      this.loading = true;
      this.resetAllExpanded();
      if (this.multiPeriodActive) {
        await this.refreshOrCutMultiPeriod(this.options);
      } else {
        await this.refreshOrCut(this.options);
      }
      this.loading = false;
    },
    async reRunCalculation() {
      let itemsPerPage = getRowsPerPageForLength(
        this.selected.length,
        this.rowsPerPage
      );
      this.runningCalculation = true;
      this.resetAllExpanded();
      this.allAccountsLoaded = [];
      await this.reRun({
        itemsPerPage: itemsPerPage,
        multiPeriod: this.multiPeriodActive,
        all: this.allSelectedAccounts
      });
      this.selectNone();
      this.runningCalculation = false;
    },
    statusNotAllowed(accountsSelected) {
      var i = 0;
      var found = false;
      while (i < accountsSelected.length && !found) {
        if (this.disallowedStatusesForRun.includes(accountsSelected[i].status)) {
          found = true;
        }
        i++;
      }
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    back() {
      this.selectNone();
      this.resetAllExpanded();
      this.$emit("back");
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
  beforeMount() {
    this.resetSelectedAllAccounts();
  }
};
</script>

<style>
/*Light Theme*/
/*rows*/
.all-selected.theme--light.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--light.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}
/*Dark Theme*/
/*rows*/
.all-selected.theme--dark.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--dark.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}
.hover-disabled {
  pointer-events: none;
}
</style>

<template>
  <v-dialog v-model="show" max-width="750" @keydown.esc="close">
    <running-dialog
      message="Updating adjustment. Please wait..."
      :showDialog.sync="updatingAdjustment"
    ></running-dialog>
    <success-dialog
      message="Adjusted successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <error-dialog
      :message="updateErrorMessage"
      :showDialog.sync="showUpdateErrorDialog"
    ></error-dialog>
    <confirmation-dialog
      :showDialog.sync="showDeleteConfirmationDialog"
      message="Are you sure you want to delete this adjustment?"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    ></confirmation-dialog>
    <running-dialog
      message="Deleting adjustment. Please wait..."
      :showDialog.sync="deletingAdjustment"
    ></running-dialog>
    <success-dialog
      message="Deleted successfully"
      :showDialog.sync="deleteSuccess"
    ></success-dialog>
    <error-dialog
      :message="deleteErrorMessage"
      :showDialog.sync="showDeleteErrorDialog"
    ></error-dialog>
    <new-adjustment
      :showDialog.sync="showNewAdjustmentDialog"
      :billingRecordId="billingRecordId"
      :refreshData="refreshData"
    ></new-adjustment>
    <v-card>
      <v-card-title>
        <span class="headline"> Adjustments </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-data-table
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :headers="headers"
        :items="adjustments"
        item-key="id"
        :hide-default-footer="adjustments.length <= 10"
      >
        <template v-slot:[`item.adjustmentType`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="info-icon">
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              {{ adjTypeDisplayValue(item.adjustmentType) }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.adjustmentNote`]="{ item }">
          <v-form
            v-if="adjustmentEditing(item)"
            ref="formNote"
            v-model="validNote"
          >
            <v-text-field
              v-model="newAdjustment.adjustmentNote"
              :rules="rules.adjustmentNote"
              :color="colorTheme.textField"
            >
            </v-text-field>
          </v-form>
          <td v-else>{{ item.adjustmentNote }}</td>
        </template>
        <template v-slot:[`item.adjustmentValue`]="{ item }">
          <v-form
            v-if="adjustmentEditing(item)"
            ref="formValue"
            v-model="validValue"
          >
            <v-text-field
              v-model="newAdjustment.adjustmentValue"
              :rules="rules.adjustmentValue"
              :color="colorTheme.textField"
              prefix="$"
            >
            </v-text-field>
          </v-form>
          <cell-monetary-value v-else :value="item.adjustmentValue" />
        </template>
        <template v-slot:[`item.save`]="{ item }">
          <v-btn
            v-if="adjustmentEditing(item)"
            small
            @click="updateItem(item)"
            :color="colorTheme.button"
            :dark="validNote && validValue && changedValue(item)"
            :disabled="!validNote || !validValue || !changedValue(item)"
          >
            Save
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="adjustmentEditable(item)"
            small
            class="edit-icon"
            @click="editAdjustment(item)"
          >
            mdi-pencil
          </v-icon>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon small class="edit-icon" disabled>
                  mdi-pencil
                </v-icon>
              </span>
            </template>
            <span>
              Only manual adjustments can be modified
            </span>
          </v-tooltip>
          <v-icon
            v-if="adjustmentDeletable(item)"
            small
            class="delete-icon"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon small class="delete-icon" disabled>
                  mdi-delete
                </v-icon>
              </span>
            </template>
            <span>
              Only manual adjustments can be deleted
            </span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" :color="colorTheme.alert">
            No adjustments
          </v-alert>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn
          :color="colorTheme.button"
          dark
          tile
          class="btn-letter_transform"
          @click="addNewAdjustment"
        >
          Add adjustment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { insertWhitespaces } from "@/utils/format-util.js";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import NewAdjustment from "./NewAdjustment.vue";
import ConfirmationDialog from "../dialogs/ConfirmationDialog.vue";

export default {
  components: {
    CellMonetaryValue,
    SuccessDialog,
    ErrorDialog,
    RunningDialog,
    NewAdjustment,
    ConfirmationDialog
  },
  props: ["showDialog", "itemToAdjust", "refreshAccount"],
  emits: ["update:showDialog", "update:itemToAdjust"],
  data: () => ({
    showNewAdjustmentDialog: false,
    showDeleteConfirmationDialog: false,
    deletingAdjustment: false,
    deleteSuccess: false,
    showDeleteErrorDialog: false,
    deleteErrorMessage: "",
    defaultDeleteErrorMessage: "Deleting adjustment failed",
    itemToDelete: null,
    updatingAdjustment: false,
    updateSuccess: false,
    showUpdateErrorDialog: false,
    updateErrorMessage: "",
    defaultUpdateErrorMessage: "Updating adjustment failed",
    validNote: false,
    validValue: false,
    defaultAdjustment: {
      id: null
    },
    newAdjustment: {
      id: null
    },
    adjustments: [],
    headers: [
      {
        value: "adjustmentType",
        sortable: false
      },
      {
        text: "Note",
        value: "adjustmentNote",
        sortable: false
      },
      {
        text: "Value",
        value: "adjustmentValue",
        align: "end",
        sortable: false
      },
      {
        value: "save",
        sortable: false,
        align: "start",
      },
      {
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    sortBy: ["adjustmentType", "createdAt"],
    sortDesc: [false, true],
    rules: {
      adjustmentValue: [
        v => /^-?\d+\.?\d*$/.test(v) || "Must be a number",
        v => /^[^\s]+$/.test(v) || "No whitespace",
        v => /^-?(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(v) || "Maximum two decimals",
        v => v > Number.MIN_SAFE_INTEGER || "Must be a number",
        v => v < Number.MAX_SAFE_INTEGER || "Must be a number"
      ],
      adjustmentNote: [v => !!v || "Note is required"]
    }
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set() {
        this.close();
      }
    },
    billingRecordId() {
      if (this.itemToAdjust) {
        return this.itemToAdjust.id;
      }
      return null;
    }
  },
  watch: {
    show(val) {
      if (val && this.itemToAdjust) {
        this.adjustments = this.itemToAdjust.adjustments;
      }
    }
  },
  methods: {
    ...mapActions("adjustments", ["updateAdjustment", "deleteAdjustment"]),
    changedValue(item) {
      if (
        Number(this.newAdjustment.adjustmentValue) !==
          Number(item.adjustmentValue) ||
        this.newAdjustment.adjustmentNote !== item.adjustmentNote
      ) {
        return true;
      }
      return false;
    },
    adjustmentEditing(item) {
      return this.newAdjustment.id === item.id;
    },
    adjTypeDisplayValue(type) {
      return insertWhitespaces(type);
    },
    addNewAdjustment() {
      this.showNewAdjustmentDialog = true;
    },
    async refreshData(newBillingRecord) {
      this.resetNewAdjustment();
      this.adjustments = newBillingRecord.adjustments;
      let refreshResult = await this.refreshAccount(newBillingRecord);
      return refreshResult;
    },
    async updateItem(oldAdjustment) {
      if (this.validate()) {
        let newItem = Object.assign({}, this.newAdjustment);

        try {
          if (newItem.id !== oldAdjustment.id) {
            throw "Adjustment ID not matched.";
          }
          this.updatingAdjustment = true;
          newItem.adjustmentValue = Number.parseFloat(newItem.adjustmentValue);
          let result = await this.updateAdjustment(newItem);
          if (!result) {
            this.updateErrorMessage = this.defaultUpdateErrorMessage;
            this.showUpdateErrorDialog = true;
          } else if (result.errorMessage) {
            this.updateErrorMessage = result.errorMessage;
            this.showUpdateErrorDialog = true;
          } else {
            let refreshResult = await this.refreshData(result);
            if (refreshResult) {
              this.updateSuccess = true;
            }
          }
          this.updatingAdjustment = false;
        } catch (err) {
          console.log(err);
          this.updateErrorMessage = this.defaultUpdateErrorMessage;
          this.showUpdateErrorDialog = true;
        }
      } else {
        this.updateErrorMessage = this.defaultUpdateErrorMessage;
        this.showUpdateErrorDialog = true;
      }
    },
    editAdjustment(item) {
      this.newAdjustment = Object.assign({}, item);
    },
    deleteItem(item) {
      this.itemToDelete = item.id;
      this.showDeleteConfirmationDialog = true;
    },
    async confirmDelete() {
      this.deletingAdjustment = true;
      let result = await this.deleteAdjustment(this.itemToDelete);
      if (!result) {
        this.deleteErrorMessage = this.defaultDeleteErrorMessage;
        this.showDeleteErrorDialog = true;
      } else if (result.errorMessage) {
        this.deleteErrorMessage = result.errorMessage;
        this.showDeleteErrorDialog = true;
      } else {
        let refreshResult = await this.refreshData(result);
        if (refreshResult) {
          this.deleteSuccess = true;
        }
      }
      this.deletingAdjustment = false;
      this.itemToDelete = null;
    },
    cancelDelete() {
      this.itemToDelete = null;
    },
    adjustmentEditable(item) {
      return item.adjustmentType === "ManualUserAdjustment";
    },
    adjustmentDeletable(item) {
      return item.adjustmentType === "ManualUserAdjustment";
    },
    resetNewAdjustment() {
      this.newAdjustment = Object.assign({}, this.defaultAdjustment);
    },
    close() {
      this.resetNewAdjustment();
      this.$emit("update:showDialog", false);
      this.$emit("update:itemToAdjust", null);
    },
    validate() {
      return this.$refs.formNote.validate() && this.$refs.formValue.validate();
    },
  },
};
</script>

<template>
  <span v-if="formattedValue" class="no-wrap">
    {{ formattedValue }}
  </span>
</template>

<script>
import { formatMonetaryValueNoWhitespace } from "@/utils/format-util.js";

export default {
  props: ["value"],
  computed: {
    formattedValue() {
      return formatMonetaryValueNoWhitespace(this.value);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('error-dialog',{attrs:{"message":_vm.expandErrorMessage,"showDialog":_vm.expandErrorDialog},on:{"update:message":function($event){_vm.expandErrorMessage=$event},"update:showDialog":function($event){_vm.expandErrorDialog=$event},"update:show-dialog":function($event){_vm.expandErrorDialog=$event}}}),_c('v-data-table',{class:{ 'scrollable-table__large': true, 'all-selected': _vm.allSelected },attrs:{"fixed-header":"","headers":_vm.filteredAccountHeaders,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.itemsCount,"item-key":"runTypeKey","single-expand":"","show-expand":"","show-select":"","expanded":_vm.expandedItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPage,
      disablePagination: _vm.loading,
      disableItemsPerPage: _vm.loading,
    }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expandedItems=$event},"item-expanded":_vm.expandAccounts},scopedSlots:_vm._u([(_vm.allSelected)?{key:"header.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"disabled":"","value":true}})]}}:{key:"header.data-table-select",fn:function(ref){
    var on = ref.on;
    var props = ref.props;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"indeterminate":_vm.indeterminateAccounts()}},'v-simple-checkbox',props,false),on))]}},(_vm.allSelected)?{key:"item.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":true,"disabled":""}})]}}:{key:"item.data-table-select",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":_vm.indeterminateAccountsInPeriod(item),"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.accountId",fn:function(ref){
    var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.accountId}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('account-in-period-table',{attrs:{"headers":headers,"account":item,"selected":_vm.selectedInPeriod,"expandedAccountsInPeriod":_vm.accountsInPeriod,"expandedFees":_vm.fees,"expandedSleeves":_vm.sleeves,"resetExpandedAccountsInPeriod":_vm.resetExpandedAccountsInPeriod,"resetExpandedFees":_vm.resetExpandedFees,"resetExpandedSleeves":_vm.resetExpandedSleeves,"refreshAccount":_vm.refreshAccount,"formatDate":_vm.formatDate,"allSelected":_vm.allSelected,"footer-props":{
          'items-per-page-options': _vm.rowsPerPage,
          disablePagination: _vm.loading,
          disableItemsPerPage: _vm.loading,
        }},on:{"update:selected":function($event){_vm.selectedInPeriod=$event},"update:expandedAccountsInPeriod":function($event){_vm.accountsInPeriod=$event},"update:expanded-accounts-in-period":function($event){_vm.accountsInPeriod=$event},"update:expandedFees":function($event){_vm.fees=$event},"update:expanded-fees":function($event){_vm.fees=$event},"update:expandedSleeves":function($event){_vm.sleeves=$event},"update:expanded-sleeves":function($event){_vm.sleeves=$event}}})]}}],null,true),model:{value:(_vm.selectedAccounts),callback:function ($$v) {_vm.selectedAccounts=$$v},expression:"selectedAccounts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('error-dialog',{attrs:{"message":_vm.expandErrorMessage,"showDialog":_vm.expandErrorDialog},on:{"update:message":function($event){_vm.expandErrorMessage=$event},"update:showDialog":function($event){_vm.expandErrorDialog=$event},"update:show-dialog":function($event){_vm.expandErrorDialog=$event}}}),_c('v-data-table',{class:{ 'scrollable-table__large': true, 'all-selected': _vm.allSelected },attrs:{"fixed-header":"","headers":_vm.filteredAccountHeaders,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.itemsCount,"item-key":"runTypeKey","single-expand":"","show-expand":"","show-select":"","expanded":_vm.expandedItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPage,
      disablePagination: _vm.loading,
      disableItemsPerPage: _vm.loading,
    }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expandedItems=$event},"item-expanded":_vm.expandAccounts},scopedSlots:_vm._u([(_vm.allSelected)?{key:"header.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":true,"disabled":""}})]}}:null,(_vm.allSelected)?{key:"item.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":true,"disabled":""}})]}}:null,{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.accountId",fn:function(ref){
    var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.accountId}})]}},{key:"item.totalAumAndDate.aum",fn:function(ref){
    var item = ref.item;
return [(item.totalAumAndDate)?_c('cell-monetary-value-with-warning',{attrs:{"value":item.totalAumAndDate.aum,"show-error":true,"error-message":"No AUM"}}):_c('cell-warning',{attrs:{"text":"No AUM"}})]}},{key:"item.totalAumAndDate.aumDate",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"no-wrap"},[(item.totalAumAndDate && item.totalAumAndDate.aumDate)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.totalAumAndDate.aumDate))+" ")]):_c('cell-warning',{attrs:{"text":"No AUM Date"}})],1)]}},{key:"item.displayFromDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.displayFromDate}})]}},{key:"item.displayToDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.displayToDate}})]}},{key:"item.fee",fn:function(ref){
    var item = ref.item;
return [_c('cell-monetary-value-format',{attrs:{"value":item.amount}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('fee-types-table',{attrs:{"headers":headers,"account":item,"expandedFees":_vm.fees,"expandedSleeves":_vm.sleeves,"refreshAccount":_vm.refreshAccount},on:{"update:expandedFees":function($event){_vm.fees=$event},"update:expanded-fees":function($event){_vm.fees=$event},"update:expandedSleeves":function($event){_vm.sleeves=$event},"update:expanded-sleeves":function($event){_vm.sleeves=$event}}})]}}],null,true),model:{value:(_vm.selectedAccounts),callback:function ($$v) {_vm.selectedAccounts=$$v},expression:"selectedAccounts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
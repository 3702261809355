<template>
  <v-dialog
    v-model="showTermination"
    persistent
    width="auto"
    @keydown.esc="closeTerminationWizard('FeeAssignment')"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Termination Billing Wizard </span>
        <span class="right-corner">
          <v-icon
            @click="closeTerminationWizard('FeeAssignment')"
            class="exit-icon"
          >
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-row no-gutters>
        <termination-accounts
          v-if="screen == 'accounts'"
          @show-records="showBillingRecords"
          @show-exports="showExports"
        ></termination-accounts>
        <termination-records
          v-else-if="screen == 'records'"
          @back="showAccounts"
        ></termination-records>
        <export-results
          v-else-if="screen == 'exports'"
          @back="showAccounts"
          @next="closeTerminationWizard('DownloadPortal')"
        ></export-results>
      </v-row>
      <app-version></app-version>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TerminationAccounts from "@/components/termination-billing/TerminationAccounts.vue";
import TerminationRecords from "@/components/termination-billing/TerminationRecords.vue";
import ExportResults from "@/views/ExportResults.vue";
import AppVersion from "@/views/AppVersion.vue";

export default {
  components: {
    TerminationAccounts,
    TerminationRecords,
    ExportResults,
    AppVersion
  },
  data: () => ({
    screen: "accounts"
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("terminations", ["showTermination"]),
  },
  methods: {
    ...mapMutations("filters", [
      "resetTerminationFilters",
      "resetQuickSearchTermination"
    ]),
    ...mapMutations("terminations", ["setShowTermination"]),
    ...mapActions("terminations", ["closeTerminationWizard"]),
    showAccounts() {
      this.screen = "accounts";
    },
    showBillingRecords() {
      this.screen = "records";
    },
    showExports() {
      this.screen = "exports";
    }
  },
  mounted() {
    this.resetTerminationFilters();
    this.resetQuickSearchTermination();
    this.setShowTermination(true);
  }
};
</script>

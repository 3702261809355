<template>
  <div v-if="filterActive && showTableInfoMessage">
    <error-dialog
      :message="errorMessage"
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <warning-dialog
      :message="warningMessage"
      :showDialog.sync="showWarningDialog"
    ></warning-dialog>
    <div class="table-message">
      <div style="display: flex" class="mb-0">
        <v-icon class="ml-2 mr-2" size="20" style="margin: 0">
          mdi-information-outline
        </v-icon>
        <p class="mb-0" style="font-size: 14px">
          {{ activeFilterText }}
          <span v-if="showLinkText" class="link-text" @click="getFilterInfo">
            {{ filterInfoText }}
          </span>
          <span v-else-if="retrievingList">
            {{ filterInfoText }}. Retrieving list...
          </span>
          <span v-else>
            {{ filterInfoText }}:
            <span v-if="missingAccountsList.length <= 5">
              {{ missingAccountsText }}
            </span>
            <cell-clipboard
              :text="missingAccountsText"
              :dontShowHoverText="true"
            ></cell-clipboard>
            Copy list
          </span>
        </p>
      </div>
      <v-icon
        class="close-icon"
        size="20"
        style="margin: 0"
        @click="closeTableInfoMessage"
      >
        mdi-close
      </v-icon>
    </div>
    <v-progress-linear
      v-if="retrievingList"
      indeterminate
      :color="colorTheme.progress"
    ></v-progress-linear>
  </div>
</template>

<script>
import { getInGroupFilterUniqueValues } from "@/utils/filters-util.js";
import { mapActions, mapState } from "vuex";

import CellClipboard from "@/components/common/CellClipboard.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";

export default {
  props: [
    "screen",
    "totalResults",
    "totalDistinctResults",
    "filters",
    "loading"
  ],
  components: {
    CellClipboard,
    ErrorDialog,
    WarningDialog,
  },
  data: () => ({
    showTableInfoMessage: true,
    showLinkText: true,
    retrievingList: false,
    missingAccountsList: [],
    showErrorDialog: false,
    errorMessage: "Error! Try again.",
    showWarningDialog: false,
    warningMessage: "Number of missing accounts doesn't match."
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    inGroupFilter() {
      if (this.filters === undefined || this.filters === null) {
        return null;
      }
      let inGroupFilters = this.filters.filter(
        fil => fil.predicate === "in group" && fil.column === "name"
      );
      if (inGroupFilters.length > 0) {
        return inGroupFilters[0];
      } else {
        return null;
      }
    },
    inGroupValue() {
      if (this.inGroupFilter) {
        return this.inGroupFilter.value;
      } else {
        return null;
      }
    },
    filterActive() {
      return (
        this.inGroupFilter !== null &&
        this.numberOfMissingAccounts() > 0 &&
        !this.loading
      );
    },
    activeFilterText() {
      return "Account Number Filter: ";
    },
    filterInfoText() {
      if (this.numberOfMissingAccounts() == 1) {
        return "1 account not found";
      } else {
        return `${this.numberOfMissingAccounts()} accounts not found`;
      }
    },
    uniqueInGroupValues() {
      return getInGroupFilterUniqueValues(this.inGroupValue);
    },
    totalRequested() {
      return this.uniqueInGroupValues.length;
    },
    missingAccountsText() {
      if (this.missingAccountsList.length == 0) {
        return "";
      }
      let text = "";
      this.missingAccountsList.forEach(acc => {
        text += acc + ",";
      });
      if (text.length > 0 && text[text.length - 1] === ",") {
        text = text.slice(0, -1);
      }
      return text;
    },
  },
  watch: {
    filters(val) {
      if (val && val.length > 0) {
        this.resetMessageToDefault();
        this.retrievingList = false;
        this.showTableInfoMessage = true;
      }
    }
  },
  methods: {
    ...mapActions("feeResultsAccounts", ["getFeeReviewMissingAccounts"]),
    ...mapActions("inceptionAccounts", ["getInceptionMissingAccounts"]),
    ...mapActions("terminationAccounts", ["getTerminationMissingAccounts"]),
    async getFilterInfo() {
      this.showLinkText = false;
      this.retrievingList = true;
      let result = await this.getMissingAccounts();
      if (!this.retrievingList) {
        this.resetMessageToDefault();
        return;
      }
      if (result === false) {
        console.log("Unexpected error.");
        this.resetMessageToDefault();
        this.errorMessage = "Error! Try again.";
        this.showErrorDialog = true;
        this.retrievingList = false;
      } else if (result === null) {
        console.log("Another call is in progress.");
      } else {
        this.missingAccountsList = result;
        if (result.length != this.numberOfMissingAccounts()) {
          this.showWarningDialog = true;
        }
        this.retrievingList = false;
      }
    },
    async getMissingAccounts() {
      switch (this.screen) {
        case "feeReview":
          return await this.getFeeReviewMissingAccounts();
        case "inception":
          return await this.getInceptionMissingAccounts();
        case "termination":
          return await this.getTerminationMissingAccounts();
      }
    },
    closeTableInfoMessage() {
      this.showTableInfoMessage = false;
      this.retrievingList = false;
      this.resetMessageToDefault();
    },
    resetMessageToDefault() {
      this.showLinkText = true;
      this.missingAccountsList = [];
    },
    numberOfMissingAccounts() {
      if (this.totalDistinctResults !== -1) {
        return this.totalRequested - this.totalDistinctResults;
      }
      return this.totalRequested - this.totalResults;
    },
  }
};
</script>

<style scoped>
.table-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: #fff5e5;
  color: #7d3200;
}
@media (max-width: 913px) {
  .table-message {
    height: 50px;
  }
}
</style>

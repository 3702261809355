<template>
  <div>
    <error-dialog
      :message.sync="expandErrorMessage"
      :showDialog.sync="expandErrorDialog"
    ></error-dialog>
    <v-data-table
      fixed-header
      :class="{ 'scrollable-table__large': true, 'all-selected': allSelected }"
      :headers="filteredAccountHeaders"
      :items="items"
      v-model="selectedAccounts"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="itemsCount"
      item-key="runTypeKey"
      single-expand
      show-expand
      show-select
      :expanded.sync="expandedItems"
      @item-expanded="expandAccounts"
      :footer-props="{
        'items-per-page-options': rowsPerPage,
        disablePagination: loading,
        disableItemsPerPage: loading,
      }"
    >
      <template v-if="allSelected" v-slot:[`header.data-table-select`]="{}">
        <v-simple-checkbox disabled :value="true"> </v-simple-checkbox>
      </template>
      <template v-else v-slot:[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox
          v-bind="props"
          v-on="on"
          :indeterminate="indeterminateAccounts()"
        >
        </v-simple-checkbox>
      </template>
      <template v-if="allSelected" v-slot:[`item.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled> </v-simple-checkbox>
      </template>
      <template
        v-else
        v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
      >
        <v-simple-checkbox
          :indeterminate="indeterminateAccountsInPeriod(item)"
          :value="isSelected"
          @input="select($event)"
        >
        </v-simple-checkbox>
      </template>
      <template v-slot:progress>
        <v-progress-linear
          :color="colorTheme.progress"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-slot:[`item.accountId`]="{ item }">
        <cell-clipboard :text="item.accountId" />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <account-in-period-table
          :headers="headers"
          :account="item"
          :selected.sync="selectedInPeriod"
          :expandedAccountsInPeriod.sync="accountsInPeriod"
          :expandedFees.sync="fees"
          :expandedSleeves.sync="sleeves"
          :resetExpandedAccountsInPeriod="resetExpandedAccountsInPeriod"
          :resetExpandedFees="resetExpandedFees"
          :resetExpandedSleeves="resetExpandedSleeves"
          :refreshAccount="refreshAccount"
          :formatDate="formatDate"
          :allSelected="allSelected"
          :footer-props="{
            'items-per-page-options': rowsPerPage,
            disablePagination: loading,
            disableItemsPerPage: loading,
          }"
        ></account-in-period-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CellClipboard from "@/components/common/CellClipboard.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import AccountInPeriodTable from "./AccountInPeriodTable.vue";

export default {
  components: {
    CellClipboard,
    ErrorDialog,
    AccountInPeriodTable,
  },
  props: [
    "items",
    "selected",
    "selectedAccountsInPeriod",
    "loading",
    "options",
    "itemsCount",
    "expandedAccountsMultiPeriod",
    "expandedAccountsInPeriod",
    "expandedFees",
    "expandedSleeves",
    "resetExpandedAccountsMultiPeriod",
    "resetExpandedAccountsInPeriod",
    "resetExpandedFees",
    "resetExpandedSleeves",
    "refreshAccount",
    "formatDate",
    "allSelected",
  ],
  emits: [
    "update:selected",
    "update:selectedAccountsInPeriod",
    "update:options",
    "update:expandedAccountsMultiPeriod",
    "update:expandedAccountsInPeriod",
    "update:expandedFees",
    "update:expandedSleeves",
  ],
  data: () => ({
    expandErrorDialog: false,
    expandErrorMessage:
      "Account doesn't have billing records in this period range"
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["accountMultiPeriodHeaders"]),
    filteredAccountHeaders() {
      return this.accountMultiPeriodHeaders.filter(h => !h.dontShow);
    },
    selectedAccounts: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit("update:selected", newValue);
      }
    },
    selectedInPeriod: {
      get() {
        return this.selectedAccountsInPeriod;
      },
      set(newValue) {
        this.$emit("update:selectedAccountsInPeriod", newValue);
      }
    },
    pagination: {
      get() {
        return this.options;
      },
      set(newValue) {
        this.$emit("update:options", newValue);
      }
    },
    expandedItems: {
      get() {
        return this.expandedAccountsMultiPeriod;
      },
      set(newValue) {
        this.$emit("update:expandedAccountsMultiPeriod", newValue);
      }
    },
    expandedAccount() {
      return this.expandedItems[0];
    },
    accountsInPeriod: {
      get() {
        return this.expandedAccountsInPeriod;
      },
      set(newValue) {
        this.$emit("update:expandedAccountsInPeriod", newValue);
      }
    },
    fees: {
      get() {
        return this.expandedFees;
      },
      set(newValue) {
        this.$emit("update:expandedFees", newValue);
      }
    },
    sleeves: {
      get() {
        return this.expandedSleeves;
      },
      set(newValue) {
        this.$emit("update:expandedSleeves", newValue);
      }
    },
  },
  methods: {
    indeterminateAccounts() {
      let totalCount = 0;
      this.items.forEach(acc => {
        totalCount += acc.accountsInPeriod.length;
      });

      let selectedCount = 0;
      this.selectedAccountsInPeriod.forEach(acc => {
        let found = false;
        this.items.forEach(a => {
          if (
            a.accountId === acc.accountId &&
            a.billingRunType === acc.billingRunType
          ) {
            found = true;
          }
        });
        if (found) {
          selectedCount++;
        }
      });
      if (selectedCount > 0 && selectedCount < totalCount) {
        return true;
      } else {
        return false;
      }
    },
    indeterminateAccountsInPeriod(account) {
      let totalCount = account.accountsInPeriod.length;
      let selected = this.selectedAccountsInPeriod.filter(
        acc =>
          acc.accountId === account.accountId &&
          acc.billingRunType === account.billingRunType
      );
      let selectedCount = selected.length;

      if (selectedCount > 0 && selectedCount < totalCount) {
        return true;
      } else {
        return false;
      }
    },
    expandAccounts({ item }) {
      let account = item;
      if (this.emptyExpandedAccounts()) {
        if (this.emptyExpandedAccountsInPeriod(account)) {
          this.showExpandErrorDialog();
          this.resetExpandedAccountsMultiPeriod();
        }
        return;
      }
      if (this.accountIsExpanded(account)) {
        this.resetExpandedAccountsInPeriod();
        this.resetExpandedFees();
        this.resetExpandedSleeves();
        return;
      }
      if (this.emptyExpandedAccountsInPeriod(account)) {
        this.showExpandErrorDialog();
        this.revertExpandedAccounts(this.expandedAccount);
      } else {
        this.resetExpandedAccountsInPeriod();
        this.resetExpandedFees();
        this.resetExpandedSleeves();
      }
      return;
    },
    emptyExpandedAccounts() {
      return this.expandedItems.length == 0;
    },
    emptyExpandedAccountsInPeriod(account) {
      return account.accountsInPeriod.length == 0;
    },
    accountIsExpanded(account) {
      return this.expandedAccount == account;
    },
    revertExpandedAccounts(expanded) {
      this.resetExpandedAccountsMultiPeriod();
      this.expandedItems = [expanded];
    },
    showExpandErrorDialog() {
      this.expandErrorDialog = true;
    },
  }
};
</script>

<template>
  <v-dialog v-model="show" persistent max-width="450" @keydown.esc="close">
    <running-dialog
      message="Adding new adjustment. Please wait..."
      :showDialog.sync="addingAdjustment"
    ></running-dialog>
    <error-dialog
      :message="errorMessage"
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <v-card>
      <v-card-title>
        <span class="headline"> Add New Adjustment </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-form ref="form" v-model="valid">
        <span class="ml-2 mr-2"> Adjustment Value: </span>
        <v-text-field
          class="ml-2 mr-2"
          v-model="adjustmentValue"
          :rules="rules.adjustmentValue"
          :color="colorTheme.textField"
        >
        </v-text-field>
        <span class="ml-2 mr-2"> Adjustment Note: </span>
        <v-text-field
          class="ml-2 mr-2"
          v-model="adjustmentNote"
          :rules="rules.adjustmentNote"
          :color="colorTheme.textField"
        >
        </v-text-field>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="colorTheme.textColor"
          :color="colorTheme.buttonWhite"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="save"
          :color="colorTheme.button"
          :dark="valid"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";

export default {
  props: ["showDialog", "billingRecordId", "refreshData"],
  emits: ["update:showDialog"],
  components: {
    ErrorDialog,
    RunningDialog
  },
  data: () => ({
    addingAdjustment: false,
    showErrorDialog: false,
    errorMessage: "",
    defaultErrorMessage: "Adding adjustment failed",
    valid: false,
    adjustmentValue: null,
    adjustmentNote: "",
    defaultAdjustment: {
      adjustmentValue: null,
      adjustmentNote: "",
    },
    rules: {
      adjustmentValue: [
        v => /^-?\d+\.?\d*$/.test(v) || "Must be a number",
        v => /^[^\s]+$/.test(v) || "No whitespace",
        v => /^-?(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(v) || "Maximum two decimals",
        v => v > Number.MIN_SAFE_INTEGER || "Must be a number",
        v => v < Number.MAX_SAFE_INTEGER || "Must be a number"
      ],
      adjustmentNote: [v => !!v || "Note is required"]
    }
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.resetValues();
      }
    }
  },
  methods: {
    ...mapActions("adjustments", ["addAdjustment"]),
    async save() {
      if (this.validate()) {
        try {
          let newItem = {
            adjustmentValue: Number.parseFloat(this.adjustmentValue),
            adjustmentNote: this.adjustmentNote,
            adjustmentType: "ManualUserAdjustment",
            billingRecordId: this.billingRecordId
          };
          this.addingAdjustment = true;
          let result = await this.addAdjustment(newItem);
          if (!result) {
            this.errorMessage = this.defaultErrorMessage;
            this.showErrorDialog = true;
          } else if (result.errorMessage) {
            this.errorMessage = result.errorMessage;
            this.showErrorDialog = true;
          } else {
            await this.refreshData(result);
          }
          this.addingAdjustment = false;
        } catch (error) {
          this.showErrorDialog = true;
          console.log(error);
        }
        this.close();
      } else {
        this.errorMessage = this.defaultErrorMessage;
        this.showErrorDialog = true;
      }
    },
    resetValues() {
      this.adjustmentValue = this.defaultAdjustment.adjustmentValue;
      this.adjustmentNote = this.defaultAdjustment.adjustmentNote;
    },
    close() {
      this.show = false;
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<template>
  <span v-if="formattedValue" class="no-wrap">
    {{ formattedValue }}
  </span>
</template>

<script>
import { formatMonetaryValueUnrounded } from "@/utils/format-util.js";

export default {
  props: ["value"],
  computed: {
    formattedValue() {
      return formatMonetaryValueUnrounded(this.value);
    },
  },
};
</script>

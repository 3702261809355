<template>
  <v-dialog v-model="show" width="unset" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">
          Change End Date
        </span>
        <span class="right-corner">
          <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-layout justify-center>
            <v-date-picker
              clearable
              label="End Date"
              v-model="endDate"
              :color="theme.datePicker"
            ></v-date-picker>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 mr-2"
          :style="theme.textColor"
          :color="theme.buttonWhite"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="theme.button"
          :dark="valid && !notChanged"
          @click="confirm"
          :disabled="!valid || notChanged"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: [
    "showDialog",
    "accounts",
    "theme",
    "allSelected",
    "filters",
    "quickSearch"
  ],
  emits: ["update:showDialog", "confirm"],
  data: () => ({
    valid: false,
    endDate: null
  }),
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    notChanged() {
      return this.endDate == null;
    }
  },
  watch: {
    show(val) {
      setTimeout(() => {
        if (val) {
          if (this.allSelected) {
            this.setPropertiesFilters(this.filters);
            this.setQuickSearchParam(this.quickSearch);
          } else {
            this.resetPropertiesFilters();
            this.resetQuickSearchParam();
          }
        }
      }, 100);
    }
  },
  methods: {
    ...mapMutations("addUpdateAccounts", [
      "setPropertiesFilters",
      "resetPropertiesFilters",
      "setQuickSearchParam",
      "resetQuickSearchParam"
    ]),
    async confirm() {
      let payload = { endDate: this.endDate };
      if (this.allSelected) {
        payload = { ...payload, all: true };
      } else {
        payload = { ...payload, accounts: this.accounts };
      }
      this.$emit("confirm", payload);
      this.cancel();
    },
    cancel() {
      this.reset();
      this.$emit("update:showDialog", false);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.endDate = null;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<template>
  <td :colspan="headers.length">
    <error-dialog
      :message.sync="expandErrorMessage"
      :showDialog.sync="expandErrorDialog"
    ></error-dialog>
    <v-data-table
      fixed-header
      :class="{ 'scrollable-table__large': true, 'all-selected': allSelected }"
      :headers="filteredAccountHeaders"
      :items="account.accountsInPeriod"
      v-model="selectedAccountsInPeriod"
      item-key="periodKey"
      single-expand
      show-expand
      show-select
      :expanded.sync="expandedItems"
      @item-expanded="expandAccountsInPeriod"
      :hide-default-footer="rowNumberCheck(account.accountsInPeriod)"
      :footer-props="{ 'items-per-page-options': rowsPerPage }"
    >
      <template v-if="allSelected" v-slot:[`header.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled> </v-simple-checkbox>
      </template>
      <template v-if="allSelected" v-slot:[`item.data-table-select`]="{}">
        <v-simple-checkbox :value="true" disabled> </v-simple-checkbox>
      </template>
      <template v-slot:[`item.billingPeriod`]="{ item }">
        <td class="no-wrap">
          {{ formatPeriod(getNextRunInt(item.billingPeriod)) }}
        </td>
      </template>
      <template v-slot:[`item.totalAumAndDate.aum`]="{ item }">
        <cell-monetary-value-with-warning
          v-if="item.totalAumAndDate"
          :value="item.totalAumAndDate.aum"
          :show-error="true"
          error-message="No AUM"
        />
        <cell-warning v-else text="No AUM" />
      </template>
      <template v-slot:[`item.totalAumAndDate.aumDate`]="{ item }">
        <td class="no-wrap">
          <span v-if="item.totalAumAndDate && item.totalAumAndDate.aumDate">
            {{ formatDate(item.totalAumAndDate.aumDate) }}
          </span>
          <cell-warning v-else text="No AUM Date" />
        </td>
      </template>
      <template v-slot:[`item.displayFromDate`]="{ item }">
        <date-cell :value="item.displayFromDate" />
      </template>
      <template v-slot:[`item.displayToDate`]="{ item }">
        <date-cell :value="item.displayToDate" />
      </template>
      <template v-slot:[`item.fee`]="{ item }">
        <cell-monetary-value-format :value="item.amount" />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <fee-types-table
          :headers="headers"
          :account="item"
          :expandedFees.sync="fees"
          :expandedSleeves.sync="sleeves"
          :refreshAccount="refreshAccount"
        ></fee-types-table>
      </template>
    </v-data-table>
  </td>
</template>

<script>
import { mapState } from "vuex";
import { formatPeriod } from "@/utils/format-util.js";
import { getNextRunInt } from "@/utils/period-util.js";

import CellMonetaryValueFormat from "@/components/common/CellMonetaryValueFormat.vue";
import CellMonetaryValueWithWarning from "@/components/common/CellMonetaryValueWithWarning.vue";
import CellWarning from "@/components/common/CellWarning.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import FeeTypesTable from "./FeeTypesTable.vue";
import DateCell from "@/components/common/DateCell.vue";

export default {
  components: {
    CellMonetaryValueFormat,
    CellMonetaryValueWithWarning,
    CellWarning,
    ErrorDialog,
    FeeTypesTable,
    DateCell,
  },
  props: [
    "headers",
    "account",
    "selected",
    "expandedAccountsInPeriod",
    "expandedFees",
    "expandedSleeves",
    "resetExpandedAccountsInPeriod",
    "resetExpandedFees",
    "resetExpandedSleeves",
    "refreshAccount",
    "formatDate",
    "allSelected",
  ],
  emits: [
    "update:selected",
    "update:expandedAccountsInPeriod",
    "update:expandedFees",
    "update:expandedSleeves",
  ],
  data: () => ({
    expandErrorDialog: false,
    expandErrorMessage: "",
    defaultErrorMessage: "Account doesn't have billing records in this period",
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage", "minRowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["accountInPeriodHeaders"]),
    filteredAccountHeaders() {
      return this.accountInPeriodHeaders.filter(h => !h.dontShow);
    },
    selectedAccountsInPeriod: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit("update:selected", newValue);
      }
    },
    expandedItems: {
      get() {
        return this.expandedAccountsInPeriod;
      },
      set(newValue) {
        this.$emit("update:expandedAccountsInPeriod", newValue);
      }
    },
    expandedAccountInPeriod() {
      return this.expandedItems[0];
    },
    fees: {
      get() {
        return this.expandedFees;
      },
      set(newValue) {
        this.$emit("update:expandedFees", newValue);
      }
    },
    sleeves: {
      get() {
        return this.expandedSleeves;
      },
      set(newValue) {
        this.$emit("update:expandedSleeves", newValue);
      }
    },
  },
  methods: {
    rowNumberCheck(array) {
      if (array.length <= this.minRowsPerPage) {
        return true;
      } else {
        return false;
      }
    },
    expandAccountsInPeriod({ item }) {
      let account = item;
      if (this.emptyExpandedAccountsInPeriod()) {
        if (this.emptyFees(account)) {
          this.showExpandErrorDialog(account);
          this.resetExpandedAccountsInPeriod();
        }
        return;
      }
      if (this.accountInPeriodIsExpanded(account)) {
        this.resetExpandedFees();
        this.resetExpandedSleeves();
        return;
      }
      // Other account is expanded
      if (this.emptyFees(account)) {
        this.showExpandErrorDialog(account);
        this.revertExpandedAccountsInPeriod(this.expandedAccountInPeriod);
      } else {
        this.resetExpandedFees();
        this.resetExpandedSleeves();
      }
      return;
    },
    emptyExpandedAccountsInPeriod() {
      return this.expandedItems.length == 0;
    },
    emptyFees(account) {
      return account.fees.length == 0;
    },
    accountInPeriodIsExpanded(account) {
      return this.expandedAccountInPeriod == account;
    },
    revertExpandedAccountsInPeriod(expanded) {
      this.resetExpandedAccountsInPeriod();
      this.expandedItems = [expanded];
    },
    showExpandErrorDialog(account) {
      if (account.displayErrorMessage) {
        this.expandErrorMessage = account.displayErrorMessage;
      } else {
        this.expandErrorMessage = this.defaultErrorMessage;
      }
      this.expandErrorDialog = true;
    },
    formatPeriod(period) {
      return formatPeriod(period);
    },
    getNextRunInt(period) {
      return getNextRunInt(period);
    },
  }
};
</script>

<template>
  <span v-if="formattedValue" class="no-wrap">
    {{ formattedValue }}
  </span>
</template>

<script>
import { formatMonetaryValue } from "@/utils/format-util.js";

export default {
  props: ["value"],
  computed: {
    formattedValue() {
      return formatMonetaryValue(this.value);
    },
  },
};
</script>

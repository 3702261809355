<template>
  <td :colspan="headers.length">
    <v-data-table
      fixed-header
      class="scrollable-table__medium"
      :headers="sleeveHeaders"
      :items="feeType.sleeves"
      item-key="sleeveId"
      single-expand
      show-expand
      :expanded.sync="expandedItems"
      :hide-default-footer="rowNumberCheck(feeType.sleeves)"
      :footer-props="{ 'items-per-page-options': rowsPerPage }"
    >
      <template v-slot:[`item.sleeveName`]="{ item }">
        <cell-clipboard :text="item.sleeveId" />
        {{ item.sleeveName }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <billing-records-table
          :headers="headers"
          :sleeve="item"
          :expandedSleeve="expandedItems[0]"
          :refreshAccount="refreshAccount"
        ></billing-records-table>
      </template>
    </v-data-table>
  </td>
</template>

<script>
import { mapState } from "vuex";

import CellClipboard from "@/components/common/CellClipboard.vue";
import BillingRecordsTable from "./BillingRecordsTable.vue";

export default {
  components: {
    CellClipboard,
    BillingRecordsTable,
  },
  props: ["headers", "feeType", "expandedSleeves", "refreshAccount"],
  emits: ["update:expandedSleeves"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["rowsPerPage", "minRowsPerPage"]),
    ...mapState("reviewFeesHeaders", ["sleeveHeaders"]),
    expandedItems: {
      get() {
        return this.expandedSleeves;
      },
      set(newValue) {
        this.$emit("update:expandedSleeves", newValue);
      }
    }
  },
  methods: {
    rowNumberCheck(array) {
      if (array.length <= this.minRowsPerPage) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"unset"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('running-dialog',{attrs:{"message":"Getting AUM Information. Please wait...","showDialog":_vm.loading},on:{"update:showDialog":function($event){_vm.loading=$event},"update:show-dialog":function($event){_vm.loading=$event}}}),_c('error-dialog',{attrs:{"message":_vm.aumErrorMessage,"showDialog":_vm.showAumError},on:{"update:showDialog":function($event){_vm.showAumError=$event},"update:show-dialog":function($event){_vm.showAumError=$event}}}),_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"mr-2"},[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.accountHeaders,"items":_vm.accountItems,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.billingPeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPeriodString(item.billingPeriod))+" ")]}},{key:"item.hasOtherExclusion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hasOtherExclusion ? "Yes" : "No")+" ")]}},{key:"item.hasCashExclusion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hasCashExclusion ? "Yes" : "No")+" ")]}}],null,true)})],1),_c('span',{staticClass:"right-corner"},[_c('v-icon',{staticClass:"exit-icon",on:{"click":_vm.close}},[_vm._v(" mdi-close ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"color":"black","disabled":_vm.noPreviousPeriod},on:{"click":_vm.getFirstPeriodAumData}},[_vm._v(" mdi-chevron-double-left ")]),_c('v-icon',{attrs:{"color":"black","disabled":_vm.noPreviousPeriod},on:{"click":_vm.getPreviousPeriodAumData}},[_vm._v(" mdi-chevron-left ")]),_c('v-icon',{attrs:{"color":"black","disabled":_vm.noNextPeriod},on:{"click":_vm.getNextPeriodAumData}},[_vm._v(" mdi-chevron-right ")]),_c('v-icon',{attrs:{"color":"black","disabled":_vm.noNextPeriod},on:{"click":_vm.getLastPeriodAumData}},[_vm._v(" mdi-chevron-double-right ")])],1)],1),_c('v-divider',{staticStyle:{"border-width":"0.5px","border-color":"black"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-0 mr-0",attrs:{"justify":"space-between"}},[_c('span',{staticClass:"pl-6 pt-3"},[_c('cell-clipboard',{attrs:{"text":_vm.aumItemsText,"hoverText":'Copy table to clipboard',"large":true}})],1),_c('span',{staticClass:"pr-6",staticStyle:{"font-size":"14px","text-align":"right"}},[_c('v-card-text',{staticClass:"pl-0 pr-0 pt-0 pb-0 text-right"},[_vm._v(" Average Daily Balance: ")]),(_vm.accountAumData && _vm.accountAumData.averageValue)?_c('span',[_c('cell-monetary-value-no-whitespace',{attrs:{"value":_vm.accountAumData.averageValue}})],1):_vm._e()],1)]),_c('v-container',[_c('v-data-table',{attrs:{"fixed-header":"","dense":"","headers":_vm.aumHeaders,"items":_vm.aumItems,"item-key":"allocationValueDate","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.allocationValueDate",fn:function(ref){
var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.allocationValueDate}})]}},{key:"item.aumValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.aumValue}})]}},{key:"item.cashValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.cashValue}})]}},{key:"item.nonCashExclusionValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.nonCashExclusionValue}})]}},{key:"item.netValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.netValue}})]}}],null,true)}),(_vm.showPriorAdvance)?_c('v-container',{staticClass:"pt-0 pl-0 grey-text"},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Prior Advance ")]),_c('v-data-table',{staticClass:"grey-text",attrs:{"dense":"","headers":_vm.aumHeaders,"items":_vm.priorAdvanceItems,"item-key":"allocationValueDate","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.allocationValueDate",fn:function(ref){
var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.allocationValueDate}})]}},{key:"item.aumValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.aumValue}})]}},{key:"item.cashValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.cashValue}})]}},{key:"item.nonCashExclusionValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.nonCashExclusionValue}})]}},{key:"item.netValue",fn:function(ref){
var item = ref.item;
return [_c('cell-monetary-value-no-whitespace',{attrs:{"value":item.netValue}})]}}],null,true)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-card>
      <v-card-title> Generated Exports </v-card-title>
      <div>
        <v-card-text v-for="(file, index) in payoutFiles" :key="index">
          {{ file }}
        </v-card-text>
      </div>
      <div>
        <v-card-text v-for="(file, index) in custodianFiles" :key="index">
          {{ file }}
        </v-card-text>
      </div>
      <v-row justify="space-between" class="ml-0 mr-0">
        <v-btn class="mb-2 ml-2" :color="colorTheme.button" dark @click="back">
          Back
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="colorTheme.button"
          dark
          @click="goToExportPage()"
        >
          Go to Exports
        </v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  emits: ["back", "next"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("exports", ["payoutExportResults", "custodianExportResults"]),
    payoutFiles() {
      return this.payoutExportResults;
    },
    custodianFiles() {
      return this.custodianExportResults;
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    goToExportPage() {
      //this.$emit("next");
      let newRoute = this.$router.resolve({ name: "DownloadPortal" });
      window.open(newRoute.href);
    }
  }
};
</script>

<template>
  <v-dialog v-model="show" max-width="650" @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span class="headline"> Fee Details </span>
        <span class="right-corner">
          <v-icon @click="close" class="exit-icon">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <div v-for="(property, key) in properties" :key="key">
        <v-card>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-text> {{ property.name }} </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text> {{ getValue(property) }} </v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark :color="colorTheme.button" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { mapFeeTypeValueToDisplayName } from "@/utils/mapping-util.js";
import { formatMonetaryValue } from "@/utils/format-util.js";
import { getPercentageFromNumber } from "@/utils/billing-records.js";

export default {
  props: ["showDialog", "item", "sumOfAdjustments"],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("feeSchedule", ["billingFeeTypes"]),
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    properties() {
      if (!this.item) {
        return [];
      }
      return [
        {
          name: "Period Start Date",
          value:
            this.item.periodStartDate != null
              ? this.item.periodStartDate.split("T")[0]
              : ""
        },
        {
          name: "Period End Date",
          value:
            this.item.periodEndDate != null
              ? this.item.periodEndDate.split("T")[0]
              : ""
        },
        {
          name: "Fee Schedule ID",
          value: this.item.feeScheduleId,
        },
        {
          name: "Billing Discount Type",
          value: this.item.billingDiscountType,
        },
        {
          name: "Billing Fee Type",
          value: this.item.billingFeeType,
        },
        {
          name: "Billing Aum Basis Type",
          value: this.item.billingAumBasisType,
        },
        {
          name: "Entity Aum Basis",
          value: formatMonetaryValue(this.item.entityAumBasis),
        },
        {
          name: "Holding Days In Period",
          value: this.item.holdingDaysInPeriod,
        },
        {
          name: "Total Trading Days In Month",
          value: this.item.totalTradingDaysInMonth,
        },
        {
          name: "Entity Aum",
          value: formatMonetaryValue(this.item.entityAum),
        },
        {
          name: "Average Fee Percent",
          value: getPercentageFromNumber(this.item.averageFeePercent) + " %",
        },
        {
          name: "Last Period Advance Fee Amount",
          value: formatMonetaryValue(this.item.lastPeriodAdvanceFeeAmount),
        },
        {
          name: "This Period Advance Fee Estimate",
          value: formatMonetaryValue(this.item.thisPeriodAdvanceFeeEstimate)
        },
        {
          name: "This Month Arrears Fee Amount",
          value: formatMonetaryValue(this.item.thisMonthArrearsFeeAmount),
        },
        {
          name: "This Period Arrears Fee Amount",
          value: formatMonetaryValue(this.item.thisPeriodArrearsFeeAmount),
        },
        {
          name: "Fee Schedule Minimum",
          value: formatMonetaryValue(this.item.feeScheduleMinimum),
        },
        {
          name: "Fee Schedule Maximum",
          value: formatMonetaryValue(this.item.feeScheduleMaximum),
        },
        {
          name: "Adjustments",
          value: formatMonetaryValue(
            this.sumOfAdjustments(this.item.adjustments)
          )
        },
      ];
    }
  },
  methods: {
    getValue(property) {
      if (property.name === "Billing Fee Type") {
        return mapFeeTypeValueToDisplayName(
          property.value,
          this.billingFeeTypes
        );
      } else {
        return property.value;
      }
    },
    close() {
      this.show = false;
    },
  }
};
</script>

<template>
  <v-container fluid>
    <error-dialog
      message="Loading terminations failed! Try again"
      :showDialog.sync="searchError"
    ></error-dialog>
    <running-dialog
      message="Updating properties. Please wait..."
      :showDialog.sync="updatingProperties"
    ></running-dialog>
    <success-dialog
      :message="runStartedMessage"
      :showDialog.sync="runCalcStartSuccess"
    ></success-dialog>
    <warning-dialog
      :message="calculationWarningMessage"
      :showDialog.sync="calculationWarningDialog"
    ></warning-dialog>
    <error-dialog
      message="Update error! Try again."
      :showDialog.sync="updatingError"
    ></error-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <warning-dialog
      :message="warningMessage"
      :showDialog.sync="showWarningDialog"
    ></warning-dialog>
    <running-dialog
      message="Getting billing records. Please wait..."
      :showDialog.sync="gettingBillingRecords"
    ></running-dialog>
    <error-dialog
      message="Billing Records Error! Try again."
      :showDialog.sync="showBillingRecordsErrorDialog"
    ></error-dialog>
    <empty-exports-dialog
      :showDialog.sync="showEmptyExportsDialog"
    ></empty-exports-dialog>
    <error-dialog
      message="Export error!"
      :showDialog.sync="showExportErrorDialog"
    ></error-dialog>
    <success-dialog
      :message="exportStartedMessage"
      :showDialog.sync="exportStarted"
    ></success-dialog>
    <warning-dialog
      :message="exportWarningMessage"
      :showDialog.sync="exportWarningDialog"
    ></warning-dialog>
    <info-dialog
      :message="exportInfoMessage"
      :showDialog.sync="showExportInfoMessage"
    ></info-dialog>
    <column-configurator
      :showDialog.sync="showConfiguratorDialog"
      :defaultHeaders="headers"
      :savedHeaders.sync="savedHeaders"
      :nameOfSetting="terminationTableSettingName"
    ></column-configurator>
    <v-card>
      <v-card-title> Set Account Properties </v-card-title>
      <change-termination-properties
        :showDialog.sync="showChangeTerminationProperties"
        :accounts="selected"
        :theme="theme"
        :allSelected="allSelected"
        :filters="terminationFilters"
        :quickSearch="quickSearchTermination"
        @confirm="confirmUpdate"
      ></change-termination-properties>
      <div style="display: flex; justify-content: space-between;">
        <span style="width: 95%;" class="bottom-align">
          <termination-filter
            ref="filters"
            :headers="headersSelected"
            :theme="theme"
            :results="terminationAccounts"
            :totalResults="totalTerminationAccounts"
            :totalDistinctResults="totalDistinctTerminationAccounts"
            :refresh="refresh"
          ></termination-filter>
        </span>
        <span style="width: 5%;" class="bottom-align">
          <v-container fluid>
            <v-row justify="end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="showConfigurator">
                    <v-icon :color="isColumnConfiguratorUsed ? theme.icon : ''">
                      mdi-cog
                    </v-icon>
                  </v-btn>
                </template>
                <span> Choose columns </span>
              </v-tooltip>
            </v-row>
          </v-container>
        </span>
      </div>
      <advanced-account-table
        screen="termination"
        :headersSelected="headersSelected"
        :pagination.sync="options"
        :accounts="terminationAccounts"
        :totalAccounts="totalTerminationAccounts"
        :totalDistinctAccounts="totalDistinctTerminationAccounts"
        :selectedAccounts.sync="selected"
        :filters="terminationFilters"
        :allSelected="allSelected"
        :loading="loading"
        :theme="theme"
        :refresh="refresh"
        :refreshOrCut="refreshOrCut"
      ></advanced-account-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span class="number-effect_space">
          <v-row>
            <v-col cols="12" md="8">
              <v-card-text class="py-0 mx-1 my-1">
                Accounts selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelected }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                class="py-0 mx-1 my-1"
                label="ALL"
                v-model="allSelected"
                :color="theme.switch"
              ></v-switch>
            </v-col>
          </v-row>
        </span>
      </v-row>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <regular-button
            buttonText="Select None"
            :theme="theme"
            :disabled="buttonDisabled"
            :action="selectNone"
          ></regular-button>
        </span>
        <span class="mr-2">
          <button-with-tooltip
            buttonText="Set End Date"
            :tooltipText="propertiesTooltipText"
            :theme="theme"
            :disabled="buttonLimits"
            :action="changeTerminationProperties"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Export Custodian File"
            :tooltipText="exportCustodianTooltipText"
            :theme="theme"
            :disabled="buttonLimits"
            :action="generateCustodian"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Export Payout File"
            :tooltipText="exportPayoutTooltipText"
            :theme="theme"
            :disabled="buttonLimits"
            :action="generatePayout"
          ></button-with-tooltip>
          <button-with-tooltip
            buttonText="Calculate & Review Fees"
            :tooltipText="calculateFeesTooltipText"
            :theme="theme"
            :disabled="buttonDisabled"
            :action="createAndView"
          ></button-with-tooltip>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getCurrentPeriod, getPreviousRunIntFromString } from "@/utils/period-util.js";
import { getMonthYearFromYearMonthPeriod } from "@/utils/format-util.js";
import amplitude from 'amplitude-js'

import AdvancedAccountTable from "@/components/common/AdvancedAccountTable.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import EmptyExportsDialog from "@/components/fee-results/dialogs/EmptyExportsDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ChangeTerminationProperties from "./dialogs/ChangeTerminationProperties.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import TerminationFilter from "@/components/filters/TerminationFilter.vue";
import ColumnConfigurator from "@/components/dialogs/ColumnConfigurator.vue";

export default {
  emits: ["show-records", "show-exports"],
  components: {
    AdvancedAccountTable,
    RunningDialog,
    EmptyExportsDialog,
    InfoDialog,
    ErrorDialog,
    WarningDialog,
    SuccessDialog,
    ChangeTerminationProperties,
    RegularButton,
    ButtonWithTooltip,
    TerminationFilter,
    ColumnConfigurator
  },
  data: () => ({
    numberOfLoadings: 0,
    activeEffect: false,
    warningMessage: "",
    showWarningDialog: false,
    updateSuccess: false,
    searchError: false,
    runStartedMessage: "Running of calculation started. Check calculation status in Fee Review.",
    runCalcStartSuccess: false,
    gettingBillingRecords: false,
    showBillingRecordsErrorDialog: false,
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    loading: false,
    showEmptyExportsDialog: false,
    showExportErrorDialog: false,
    exportStarted: false,
    exportStartedMessage: "",
    exportWarningDialog: false,
    exportWarningMessage: "",
    showExportInfoMessage: false,
    exportInfoMessage: "",
    showChangeTerminationProperties: false,
    showConfiguratorDialog: false,
    savedHeaders: [],
    headers: [
      {
        text: "ID",
        value: "id",
        align: "center",
        sortable: false,
        selected: true,
      },
      {
        text: "Account Number",
        value: "name",
        selected: true,
      },
      {
        text: "Status",
        value: "status",
        selected: true,
      },
      {
        text: "Rep Code",
        value: "repCode",
        selected: true,
      },
      {
        text: "AUM",
        value: "aum",
        sortable: false,
        align: "end",
        selected: true,
      },
      {
        text: "AUM Date",
        value: "aumDate",
        sortable: false,
        selected: true,
      },
      {
        text: "Billable",
        value: "billableStatus",
        selected: true,
      },
      {
        text: "Fee Frequency",
        value: "billingType",
        selected: true,
      },
      {
        text: "Total Fee",
        value: "amount",
        align: "end",
        sortable: false,
        selected: true,
      },
      {
        text: "Prior Unpaid Balance",
        value: "priorUnpaid",
        align: "end",
        sortable: false,
        selected: true,
      },
      {
        text: "Start Date",
        value: "billingStartDate",
        selected: true,
      },
      {
        text: "End Date",
        value: "billingEndDate",
        selected: true,
      },
      {
        text: "Paid By",
        value: "paidBy",
        selected: true,
      },
      {
        text: "Custodian",
        value: "custodian",
        selected: true,
      },
      {
        text: "Account Status",
        value: "accountStatus",
        selected: true,
      },
    ],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "colorThemeAll"]),
    ...mapState("terminationAccounts", [
      "terminationAccounts",
      "totalTerminationAccounts",
      "totalDistinctTerminationAccounts",
      "selectedTerminationAccounts",
      "totalSelectedTerminationAccounts",
      "terminationAllSelected",
      "pagination",
      "maxItems",
    ]),
    ...mapState("billingRecordsCalculations", ["defaultPagination"]),
    ...mapState("addUpdateAccounts", ["updatingProperties", "updateError"]),
    ...mapState("filters", ["terminationFilters", "quickSearchTermination"]),
    ...mapState("columnConfigurator", ["terminationTableSettingName"]),
    propertiesTooltipText() {
      return `Set last day for which fees will be calculated. <br />
              (${this.formattedPeriod} billing data)`;
    },
    exportCustodianTooltipText() {
      return `Generate custodian file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Pending".<br />
              (${this.formattedPeriod} billing data)`;
    },
    exportPayoutTooltipText() {
      return `Generate payout file.<br />
              Retrieve this file in download portal.<br />
              Set billing record status to "Paid Out".<br />
              (${this.formattedPeriod} billing data)`;
    },
    calculateFeesTooltipText() {
      return `Calculate fee amounts for accounts & sleeves.<br />
              Set billing record status to "Calculated".<br />
              (${this.formattedPeriod} billing data)`;
    },
    updatingError: {
      get() {
        return this.updateError;
      },
      set(newValue) {
        this.setUpdateError(newValue);
      },
    },
    allSelected: {
      get() {
        return this.terminationAllSelected;
      },
      set(newValue) {
        this.setTerminationAllSelected(newValue);
      }
    },
    selected: {
      get() {
        return this.selectedTerminationAccounts;
      },
      set(newValue) {
        this.setSelectedTerminationAccounts(newValue);
      },
    },
    totalSelected: {
      get() {
        if (this.allSelected) {
          return this.totalTerminationAccounts;
        } else {
          return this.totalSelectedTerminationAccounts;
        }
      },
      set(newValue) {
        this.setTotalSelectedTerminationAccounts(newValue);
      }
    },
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    sortBy() {
      if (this.options.sortBy && this.options.sortBy.length === 1) {
        return this.options.sortBy[0];
      } else {
        return null;
      }
    },
    sortDirection() {
      if (this.options.sortDesc && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] === false ? "Ascending" : "Descending";
      } else {
        return "Ascending";
      }
    },
    buttonDisabled() {
      return this.totalSelected == 0;
    },
    buttonLimits() {
      return (
        this.buttonDisabled ||
        (!this.allSelected && this.totalSelected > this.maxItems)
      );
    },
    theme() {
      if (this.allSelected) {
        return this.colorThemeAll;
      } else {
        return this.colorTheme;
      }
    },
    quickSearchValue() {
      return this.$refs.filters.$refs.searchFilter.quickSearchValue;
    },
    billingPeriod() {
      return getCurrentPeriod();
    },
    formattedPeriod() {
      return getMonthYearFromYearMonthPeriod(this.billingPeriod);
    },
    isColumnConfiguratorUsed() {
      return this.headers.length !== this.headersSelected.length;
    },
    headersSelected() {
      let filteredHeaders = this.savedHeaders.filter(
        (header) => header.selected == true
      );
      return filteredHeaders;
    },
  },
  watch: {
    totalSelected() {
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      this.totalSelected = val.length;
      let accounts = val.map(account => {
        return {
          id: account.id,
          billingRunType: "Termination"
        };
      });
      this.setTerminationAccountsForCalculation(accounts);
    },
    allSelected(val) {
      if (val === true) {
        this.setQuickSearchFromTempParam();
      }
    },
  },
  methods: {
    ...mapMutations("terminationRecords", ["setTerminationAccountsForCalculation"]),
    ...mapMutations("terminationAccounts", [
      "setPagination",
      "setTerminationAccounts",
      "setSelectedTerminationAccounts",
      "setTotalSelectedTerminationAccounts",
      "setTerminationAllSelected"
    ]),
    ...mapActions("terminationAccounts", ["terminationSearch"]),
    ...mapActions("terminationRecords", [
      "createAndViewTerminationBillingRecords"
    ]),
    ...mapActions("addUpdateAccounts", ["updateAccountProperties"]),
    ...mapMutations("addUpdateAccounts", ["setUpdateError"]),
    ...mapActions("exports", ["filterExport"]),
    ...mapMutations("billingRecordsCalculations", [
      "setBillingRecordsPagination"
    ]),
    ...mapMutations("filters", ["setQuickSearchTermination"]),
    setQuickSearchFromTempParam() {
      if (
        this.quickSearchValue &&
        this.quickSearchValue !== this.quickSearchTermination
      ) {
        this.setQuickSearchTermination(this.quickSearchValue);
      }
    },
    showConfigurator() {
      this.showConfiguratorDialog = true;
    },
    async refreshOrCut(pageSize) {
      let array = this.terminationAccounts;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setTerminationAccounts(
          this.terminationAccounts.slice(0, pageSize)
        );
      }
    },
    async refresh(params) {
      this.numberOfLoadings++;
      this.loading = true;

      if (params && params.page) {
        this.options.page = params.page;
      }

      let payload = {
        page: this.page,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        searchParam: this.quickSearchValue
      };
      let result = await this.terminationSearch(payload);
      if (!result) {
        this.searchError = true;
      }

      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.loading = false;
      }
    },
    async confirmUpdate(payload) {
      if (payload.all) {
        payload = {
          ...payload,
          runType: "Termination",
          period: getPreviousRunIntFromString(this.billingPeriod)
        };
      }
      let result = await this.updateAccountProperties(payload);
      if (!result.error) {
        let notUpdatedCount = result.totalFailed;
        if (notUpdatedCount > 0) {
          if (result.all) {
            if (result.allFailed) {
              this.updatingError = true;
            } else {
              this.showWarningDialog = true;
              this.warningMessage = "Warning! Some attributes are not updated.";
            }
          } else {
            this.showWarningDialog = true;
            this.warningMessage =
              "Warning! " + notUpdatedCount + " attributes not updated.";
          }
        } else {
          this.updateSuccess = true;
        }
      }
      await this.refreshAndDeselect();
    },
    async generateCustodian() {
      amplitude.getInstance().logEvent("Termination [Custodian Export]", {
        "Number of accounts": this.selected.length
      });
      await this.generateExport("Custodian");
    },
    async generatePayout() {
      amplitude.getInstance().logEvent("Termination [Payout]", {
        "Number of accounts": this.selected.length
      });
      await this.generateExport("Payout");
    },
    async generateExport(exportType) {
      let payload = {
        runType: "Termination",
        period: getPreviousRunIntFromString(this.billingPeriod),
        exportType: exportType
      };
      if (this.allSelected) {
        payload = { ...payload, all: true };
      } else {
        payload = { ...payload, selectedAccounts: this.selected };
      }
      let result = await this.filterExport(payload);

      switch (result.status) {
        case "SUCCESS":
          this.$emit("show-exports");
          break;
        case "INFO":
          this.exportInfoMessage = result.message;
          this.showExportInfoMessage = true;
          break;
        case "EMPTY":
          this.showEmptyExportsDialog = true;
          break;
        case "WARNING":
          this.exportWarningMessage = result.message;
          this.exportWarningDialog = true;
          break;
        case "TIMEOUT":
          this.exportStartedMessage = `${exportType} Export is taking too long. Check status in Fee Review.`;
          this.exportStarted = true;
          break;
        default:
          this.showExportErrorDialog = true;
      }
    },
    changeTerminationProperties() {
      amplitude.getInstance().logEvent("Termination [Termination Properties]", {
        "Number of accounts": this.selected
      });
      this.showChangeTerminationProperties = !this.showChangeTerminationProperties;
    },
    selectNone() {
      this.selected = [];
      this.allSelected = false;
    },
    async createAndView() {
      this.gettingBillingRecords = true;
      let result = await this.createAndViewTerminationBillingRecords({
        page: this.defaultPagination.page,
        pageSize: this.defaultPagination.itemsPerPage,
        all: this.allSelected
      });
      this.gettingBillingRecords = false;

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showBillingRecordsErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (result.responseData) {
        amplitude.getInstance().logEvent("Termination [Payout]", {
          "Number of accounts": this.selected
        });
        this.setBillingRecordsPagination(this.defaultPagination);
        this.$emit("show-records");
      } else {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    async refreshAndDeselect() {
      this.selectNone();
      await this.refresh();
    }
  },
  async mounted() {
    await this.refreshAndDeselect();
  },
};
</script>
<style scoped>
.bottom-align {
  display: flex;
  align-items: flex-end;
}
</style>

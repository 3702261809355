<template>
  <span v-if="formattedValue" class="no-wrap">
    {{ formattedValue }}
  </span>
  <cell-warning v-else-if="showError" :text="errorMessage" />
</template>

<script>
import { formatMonetaryValueWithWarning } from "@/utils/format-util.js";
import CellWarning from "./CellWarning.vue";

export default {
  props: ["value", "showError", "errorMessage"],
  components: {
    CellWarning,
  },
  computed: {
    formattedValue() {
      return formatMonetaryValueWithWarning(this.value);
    },
  },
};
</script>

<template>
  <span>
    <error-dialog
      message="Export error!"
      :showDialog.sync="showExportErrorMessage"
    ></error-dialog>
    <warning-dialog
      :message="calculationWarningMessage"
      :showDialog.sync="calculationWarningDialog"
    ></warning-dialog>
    <regular-button
      buttonText="Download Export File"
      :theme="theme"
      :disabled="false"
      :loading="downloadingExportFile"
      :action="downloadExportFile"
    ></regular-button>
  </span>
</template>

<script>
import { exportFileExtension } from "@/config/organization-config.js";
import cookies from "vue-cookies";
import { mapState } from "vuex";
import {
  getFeeTypeEffectiveRate,
  getFeeTypeAumBasis,
  getFeeTypeAum,
  getFeeTypeFee,
  getFeeTypeTopTierRate,
  getScheduleName,
  getInheritedFrom,
  getPercentageFromNumber
} from "@/utils/billing-records.js";
import { formatDate } from "@/utils/format-util.js";

import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
  props: [
    "items",
    "itemsMultiPeriod",
    "selectedPeriod",
    "accounts",
    "allSelected",
    "multiPeriodActive",
    "totalCalculationResults",
    "totalResultsMultiPeriod",
    "selectAllAccounts",
    "selectAllAccountsMultiPeriod",
    "theme",
  ],
  components: {
    ErrorDialog,
    WarningDialog,
    RegularButton
  },
  data: () => ({
    showExportErrorMessage: false,
    downloadingExportFile: false,
    calculationWarningDialog: false,
    calculationWarningMessage: "",
  }),
  computed: {
    ...mapState("feeResultsRecords", ["periodRangeFilter"]),
    ...mapState("reviewFeesHeaders", [
      "accountMultiPeriodHeaders",
      "accountInPeriodHeaders",
      "accountHeaders",
      "feeHeaders",
      "sleeveHeaders",
      "billingRecordHeaders"
    ])
  },
  methods: {
    async downloadExportFile() {
      if (!this.downloadingExportFile) {
        this.downloadingExportFile = true;

        let extension = exportFileExtension(cookies.get("organization"));
        var fileName = "";
        var exportText = null;
        if (this.multiPeriodActive) {
          exportText = await this.createExportTextMultiPeriod();
          let feeFrom = this.periodRangeFilter.feeFrom;
          let feeTo = this.periodRangeFilter.feeTo;
          fileName = `Export ${feeFrom}-${feeTo}.${extension}`;
        } else {
          exportText = await this.createExportText();
          fileName = `Export ${this.selectedPeriod}.${extension}`;
        }

        if (exportText !== null && exportText !== undefined) {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(exportText)
          );

          element.setAttribute("download", fileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }

        this.downloadingExportFile = false;
      }
    },
    async createExportText() {
      let result = await this.getAllResultsForExport();
      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
        return null;
      } else if (result.error || result.timeout) {
        this.showExportErrorMessage = true;
        return null;
      } else if (result.responseData) {
        let exportWithColumnNames = this.addColumnNamesToExportFile();
        let exportText = this.addRowsToExportFile(
          exportWithColumnNames,
          result.responseData
        );
        if (exportText !== null && exportText !== undefined) {
          return exportText;
        } else {
          return null;
        }
      } else {
        this.showExportErrorMessage = true;
        return null;
      }
    },
    async getAllResultsForExport() {
      let returnObject = {
        error: false,
        warningMessage: false,
        timeout: false,
        responseData: []
      };

      if (this.items.length == this.totalCalculationResults) {
        returnObject.responseData = this.items;
        return returnObject;
      }

      if (this.allSelected) {
        let response = await this.selectAllAccounts({
          page: 1,
          pageSize: 1000000,
          all: true
        });
        return response;
      } else {
        let page = 0;
        let pageSize = 500;
        while (page * pageSize < this.totalCalculationResults) {
          let response = await this.selectAllAccounts({
            page: page + 1,
            pageSize: pageSize
          });
          if (response.responseData) {
            response.responseData.forEach((element) => {
              returnObject.responseData.push(element);
            });
            page += 1;
          } else {
            return response;
          }
        }
      }
      return returnObject;
    },
    addColumnNamesToExportFile() {
      let exportText = "";
      this.accountHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.feeHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.sleeveHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.billingRecordHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      if (exportText.length > 0 && exportText[exportText.length - 1] === ",") {
        exportText = exportText.slice(0, -1);
      }
      return exportText;
    },
    addRowsToExportFile(exportText, allResults) {
      let fullText = exportText;
      allResults.forEach(account => {
        let fees = account.fees;
        if (fees.length == 0) {
          fullText += "\n";
          fullText += this.addAccountInfo(account);
        } else {
          fees.forEach(fee => {
            let sleeves = fee.sleeves;
            sleeves.forEach(sleeve => {
              let records = sleeve.records;
              records.forEach(record => {
                fullText += "\n";
                fullText += this.addAccountInfo(account);
                fullText += this.addFeeTypeInfo(account, fee);
                fullText += this.addSleeveInfo(sleeve);
                fullText += this.addBillingRecordInfo(record);
              });
            });
          });
        }
      });
      return fullText;
    },
    async createExportTextMultiPeriod() {
      let result = await this.getAllResultsMultiPeriod();
      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
        return null;
      } else if (result.error || result.timeout) {
        this.showExportErrorMessage = true;
        return null;
      } else if (result.responseData) {
        let exportWithColumnNames = this.addColumnNamesMultiPeriod();
        let exportText = this.addRowsMultiPeriod(
          exportWithColumnNames,
          result.responseData
        );
        if (exportText !== null && exportText !== undefined) {
          return exportText;
        } else {
          return null;
        }
      } else {
        this.showExportErrorMessage = true;
        return null;
      }
    },
    async getAllResultsMultiPeriod() {
      let returnObject = {
        error: false,
        warningMessage: false,
        timeout: false,
        responseData: []
      };

      if (this.itemsMultiPeriod.length == this.totalResultsMultiPeriod) {
        returnObject.responseData = this.itemsMultiPeriod;
        return returnObject;
      }
      let page = 0;
      let pageSize = 500;
      while (page * pageSize < this.totalResultsMultiPeriod) {
        let response = await this.selectAllAccountsMultiPeriod({
          page: page + 1,
          pageSize: pageSize
        });
        if (response.responseData) {
          response.responseData.forEach((element) => {
            returnObject.responseData.push(element);
          });
          page += 1;
        } else {
          return response;
        }
      }
      return returnObject;
    },
    addColumnNamesMultiPeriod() {
      let exportText = "";
      this.accountMultiPeriodHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.accountInPeriodHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.feeHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.sleeveHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      this.billingRecordHeaders.forEach(header => {
        if (header.forExport) {
          exportText += header.exportText + ",";
        }
      });
      if (exportText.length > 0 && exportText[exportText.length - 1] === ",") {
        exportText = exportText.slice(0, -1);
      }
      return exportText;
    },
    addRowsMultiPeriod(exportText, allResults) {
      let fullText = exportText;
      allResults.forEach(account => {
        let accountsInPeriod = account.accountsInPeriod;
        accountsInPeriod.forEach(accInPeriod => {
          let fees = accInPeriod.fees;
          if (fees.length == 0) {
            fullText += "\n";
            fullText += this.addAccountBasicInfo(account);
            fullText += this.addAccountInPeriodInfo(accInPeriod);
          } else {
            fees.forEach(fee => {
              let sleeves = fee.sleeves;
              sleeves.forEach(sleeve => {
                let records = sleeve.records;
                records.forEach(record => {
                  fullText += "\n";
                  fullText += this.addAccountBasicInfo(account);
                  fullText += this.addAccountInPeriodInfo(accInPeriod);
                  fullText += this.addFeeTypeInfo(accInPeriod, fee);
                  fullText += this.addSleeveInfo(sleeve);
                  fullText += this.addBillingRecordInfo(record);
                });
              });
            });
          }
        });
      });
      return fullText;
    },
    addAccountBasicInfo(account) {
      let accountText = "";
      accountText += account.accountId;
      accountText += "," + account.accountName;
      accountText += "," + account.billingType;
      accountText += "," + account.repCode;
      accountText += "," + account.billingRunType;
      accountText += "," + account.paidBy;
      return accountText;
    },
    addAccountInPeriodInfo(account) {
      let accountText = "";
      accountText += "," + account.billingPeriod;
      accountText += ",";
      if (account.totalAumAndDate && account.totalAumAndDate.aum) {
        accountText += "$ " + account.totalAumAndDate.aum;
      }
      accountText += ",";
      if (account.totalAumAndDate && account.totalAumAndDate.aumDate) {
        accountText += formatDate(account.totalAumAndDate.aumDate);
      }
      accountText += ",";
      if (account.displayFromDate) {
        accountText += formatDate(account.displayFromDate);
      }
      accountText += ",";
      if (account.displayToDate) {
        accountText += formatDate(account.displayToDate);
      }
      accountText += "," + "$ " + account.amount;
      return accountText;
    },
    addAccountInfo(account) {
      let accountText = "";
      accountText += account.accountId;
      accountText += "," + account.accountName;
      accountText += "," + account.billingType;
      accountText += ",";
      if (account.totalAumAndDate && account.totalAumAndDate.aum) {
        accountText += "$ " + account.totalAumAndDate.aum;
      }
      accountText += ",";
      if (account.totalAumAndDate && account.totalAumAndDate.aumDate) {
        accountText += formatDate(account.totalAumAndDate.aumDate);
      }
      accountText += ",";
      if (account.displayFromDate) {
        accountText += formatDate(account.displayFromDate);
      }
      accountText += ",";
      if (account.displayToDate) {
        accountText += formatDate(account.displayToDate);
      }
      accountText += "," + account.repCode;
      accountText += "," + account.paidBy;
      accountText += "," + "$ " + account.amount;
      accountText += "," + account.billingRunType;
      return accountText;
    },
    addFeeTypeInfo(account, feeType) {
      let feeText = "";
      feeText += "," + feeType.name;
      feeText += "," + getFeeTypeEffectiveRate(feeType);
      feeText += ",";
      feeText += getFeeTypeTopTierRate(feeType);
      feeText += "," + "$ " + getFeeTypeAumBasis(feeType);
      feeText += "," + "$ " + getFeeTypeAum(account);
      feeText += "," + "$ " + getFeeTypeFee(feeType);
      return feeText;
    },
    addSleeveInfo(sleeve) {
      let sleeveText = "," + sleeve.sleeveName;
      return sleeveText;
    },
    addBillingRecordInfo(record) {
      let recordText = "";
      let billingRecord = record.billingRecord;
      let schedule = record.schedule;
      recordText += "," + getScheduleName(record);
      recordText += "," + "\"" + getInheritedFrom(schedule) + "\""; // This field can contain commas
      recordText += "," + billingRecord.billingType;
      recordText += "," + "$ " + billingRecord.entityAumBasis;
      recordText += "," + getPercentageFromNumber(billingRecord.averageFeePercent) + " %";
      recordText += "," + "$ " + billingRecord.entityAum;
      recordText += ",";
      if (billingRecord.aumDate) {
        recordText += formatDate(billingRecord.aumDate);
      }
      recordText += "," + "$ " + billingRecord.thisPeriodNetFeeAmount;
      recordText += ",";
      if (billingRecord.activeBillingRecord === true) {
        recordText += "Preferred";
      }
      return recordText;
    },
  }
};
</script>

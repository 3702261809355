<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        tile
        v-on="on"
        v-bind="attrs"
        class="mb-2 ml-2 btn-letter_transform"
        :color="theme.button"
        :dark="!disabled"
        :loading="loadingBar"
        @click="action"
        :disabled="disabled"
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <span>
      <p v-html="tooltipText" class="mb-0"></p>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: [
    "buttonText",
    "tooltipText",
    "theme",
    "disabled",
    "loading",
    "action"
  ],
  data: () => ({}),
  computed: {
    loadingBar() {
      if (this.loading) {
        return this.loading;
      } else {
        return false;
      }
    }
  },
  methods: {}
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"colspan":_vm.headers.length}},[_c('fee-details-dialog',{attrs:{"show-dialog":_vm.showFeeDetailsDialog,"item":_vm.selectedBillingRecord,"sumOfAdjustments":_vm.sumOfAdjustments},on:{"update:showDialog":function($event){_vm.showFeeDetailsDialog=$event},"update:show-dialog":function($event){_vm.showFeeDetailsDialog=$event}}}),_c('list-of-adjustments',{attrs:{"showDialog":_vm.showAdjustmentDialog,"itemToAdjust":_vm.itemToAdjust,"refreshAccount":_vm.refreshAccount},on:{"update:showDialog":function($event){_vm.showAdjustmentDialog=$event},"update:show-dialog":function($event){_vm.showAdjustmentDialog=$event},"update:itemToAdjust":function($event){_vm.itemToAdjust=$event},"update:item-to-adjust":function($event){_vm.itemToAdjust=$event}}}),_c('running-dialog',{attrs:{"message":"Setting active billing record...","showDialog":_vm.setingActiveRecord},on:{"update:showDialog":function($event){_vm.setingActiveRecord=$event},"update:show-dialog":function($event){_vm.setingActiveRecord=$event}}}),_c('error-dialog',{attrs:{"message":"Can't set active billing record!","showDialog":_vm.activeRecordErrorMessage},on:{"update:showDialog":function($event){_vm.activeRecordErrorMessage=$event},"update:show-dialog":function($event){_vm.activeRecordErrorMessage=$event}}}),_c('v-data-table',{staticClass:"scrollable-table__medium",attrs:{"fixed-header":"","headers":_vm.billingRecordHeaders,"items":_vm.sleeve.records,"item-key":"name","hide-default-footer":_vm.rowNumberCheck(_vm.sleeve.records),"footer-props":{
      'items-per-page-options': _vm.rowsPerPage
    }},scopedSlots:_vm._u([{key:"item.feeDetails",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"info-icon",attrs:{"small":""},on:{"click":function($event){return _vm.showFeeDetails(item.billingRecord)}}},[_vm._v(" mdi-information-outline ")])]}},{key:"item.schedule.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getScheduleName(item))+" ")]}},{key:"item.inheritedFrom",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getInheritedFrom(item.schedule))+" "),(item.schedule && item.schedule.entity1)?[_c('cell-clipboard',{attrs:{"text":item.schedule.entity1.id}})]:_vm._e()],2)]}},{key:"item.entityAumBasis",fn:function(ref){
    var item = ref.item;
return [_c('cell-monetary-value',{attrs:{"value":item.billingRecord.entityAumBasis}})]}},{key:"item.averageFeePercent",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.getPercentageFromNumber(item.billingRecord.averageFeePercent) + " %")+" ")])]}},{key:"item.entityAum",fn:function(ref){
    var item = ref.item;
return [_c('cell-monetary-value',{attrs:{"value":item.billingRecord.entityAum}})]}},{key:"item.billingRecord.aumDate",fn:function(ref){
    var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.billingRecord.aumDate}})]}},{key:"item.thisPeriodNetFeeAmount",fn:function(ref){
    var item = ref.item;
return [_c('cell-monetary-value-format',{attrs:{"value":item.billingRecord.thisPeriodNetFeeAmount}})]}},{key:"item.adjustments",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.adjust(item)}}},[_vm._v(" Adjust ( "),_c('cell-monetary-value',{attrs:{"value":_vm.sumOfAdjustments(item.billingRecord.adjustments)}}),_vm._v(" ) ")],1)]}},{key:"item.preferred",fn:function(ref){
    var item = ref.item;
return [(item.billingRecord.activeBillingRecord == true)?_c('v-icon',{attrs:{"x-large":"","color":_vm.colorTheme.success}},[_vm._v(" mdi-check-bold ")]):_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.setAsPreferred(item)}}},[_vm._v(" Set as preferred ")])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":_vm.colorTheme.alert}},[_vm._v(" "+_vm._s(_vm.noBillingRecordsText)+" ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }